import { currentUnixTime, fourYearInUnix } from "@eltk/staking-sdk";
import { timeStamp } from "console";
import { LOCKINGTIME, NUMBER_OF_WEEKS_PER_YEAR } from "constants/misc";
const { ethers } = require("ethers");

export const getUnixTimestamp = (duration: string): number => {
  const lockingTimeInSeconds = LOCKINGTIME[duration];
  if (lockingTimeInSeconds !== undefined) {
    return lockingTimeInSeconds;
  } else {
    throw new Error(`Invalid duration: ${duration}`);
  }
};

export function getUnixTimestampAfter4Years(): number {
  const currentTimestamp: number = currentUnixTime();
  return currentTimestamp + fourYearInUnix;
}
export function getUnixTimestampAfter4YearsExtend(
  currentTimestamp: any
): number {
  return 124416000;
}

export function getUnixTimestampAfter4YearsCheck(): number {
  const currentTimestamp: number = currentUnixTime();
  return currentTimestamp + 124416000;
}

export function getUnixTimestampAfter4YearsLock(): number {
  return 124416000;
}

export function getUnixTimestampAfter4YearsLockAdd(val: any): number {
  return val + 124416000;
}

export const calculateLockingDate = (selectedOption: any) => {
  const currentDate = new Date();
  let lockingDate;

  switch (selectedOption) {
    case "1h":
      lockingDate = new Date(currentDate.getTime() + 3600 * 1000);
      break;
    case "1W":
      lockingDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
      break;
    case "1M":
      lockingDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        currentDate.getDate(),
        currentDate.getHours(),
        currentDate.getMinutes(),
        currentDate.getSeconds()
      );
      break;
    case "6M":
      lockingDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 6,
        currentDate.getDate(),
        currentDate.getHours(),
        currentDate.getMinutes(),
        currentDate.getSeconds()
      );
      break;
    case "1Y":
      lockingDate = new Date(
        currentDate.getFullYear() + 1,
        currentDate.getMonth(),
        currentDate.getDate(),
        currentDate.getHours(),
        currentDate.getMinutes(),
        currentDate.getSeconds()
      );
      break;
    case "4Y":
      lockingDate = new Date(
        currentDate.getFullYear() + 4,
        currentDate.getMonth(),
        currentDate.getDate(),
        currentDate.getHours(),
        currentDate.getMinutes(),
        currentDate.getSeconds()
      );
      break;
    default:
      lockingDate = null;
      break;
  }

  return lockingDate ? lockingDate.toLocaleString() : "Invalid Option";
};

export const sortData = (data: any, column: any, ascending: any) => {
  return data.slice().sort((a: any, b: any) => {
    const aValue = column === "hash" ? a.transactionHash : a[column];
    const bValue = column === "hash" ? b.transactionHash : b[column];

    if (ascending) {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });
};

export function getTimeDifference(t1: any, t2: any) {
  const millisecondsPerMinute = 1000 * 60;
  const millisecondsPerHour = millisecondsPerMinute * 60;
  const millisecondsPerDay = millisecondsPerHour * 24;
  const millisecondsPerWeek = millisecondsPerDay * 7;
  const millisecondsPerMonth = millisecondsPerDay * 30; // Approximation for a month
  const millisecondsPerYear = millisecondsPerDay * 365; // Approximation for a year

  const difference = Math.abs(t2 * 1000 - t1 * 1000); // Convert seconds to milliseconds

  // Convert difference to weeks, months, days, hours, minutes, and years
  const weeks = Math.floor(difference / millisecondsPerWeek);
  const days = Math.floor(difference / millisecondsPerDay);
  const hours = Math.floor(difference / millisecondsPerHour);
  const minutes = Math.floor(difference / millisecondsPerMinute);
  const months = Math.floor(difference / millisecondsPerMonth);
  const years = Math.floor(difference / millisecondsPerYear);

  // Return the time difference in the format of weeks, months, days, hours, minutes, or years
  if (years >= 1) {
    return years + (years === 1 ? " year" : " years");
  } else if (months >= 1) {
    return months + (months === 1 ? " month" : " months");
  } else if (weeks >= 1) {
    return weeks + (weeks === 1 ? " week" : " weeks");
  } else if (days >= 1) {
    return days + (days === 1 ? " day" : " days");
  } else if (hours >= 1) {
    return hours + (hours === 1 ? " hour" : " hours");
  } else {
    return minutes + (minutes === 1 ? " minute" : " minutes");
  }
}

export function getLockDuration(lockDuration: any) {
  switch (lockDuration) {
    case "1Y":
      return "1 Year";
    case "1W":
      return "1 Week";
    case "1M":
      return "1 Month";
    case "1h":
      return "1 Hour";
    case "6M":
      return "6 Months";
    case "4Y":
      return "4 Years";
    default:
      return "1 Year";
  }
}

export function estimateWeeklyRewardRate(
  weeklyRewardsDistributed: any,
  totalStakedBeforeRewards: any
) {
  if (totalStakedBeforeRewards === 0n) {
    throw new Error("Total staked before rewards cannot be zero.");
  }

  if (weeklyRewardsDistributed && totalStakedBeforeRewards) {
    let weeklyRateFraction =
      weeklyRewardsDistributed / totalStakedBeforeRewards;

    let weeklyRatePercentage = weeklyRateFraction * ethers.BigNumber.from(100);

    return weeklyRatePercentage;
  }
  return "";
}

export function calculateAPY(weeklyRewardRatePercent: any) {
  let weeklyRewardRate = weeklyRewardRatePercent / ethers.BigNumber.from(100);

  let weeklyRewardRateNumber = parseFloat(
    ethers.utils.formatUnits(weeklyRewardRate, 18)
  );

  let APY = Math.pow(1 + weeklyRewardRateNumber, NUMBER_OF_WEEKS_PER_YEAR) - 1;
  return APY;
}

export const GetNftBalance = async (tokenId: any, locking: any) => {
  const balance = await locking.balanceOfNFT(tokenId);
  return parseInt(balance?._hex);
};

export const getDateEpochFormat = (timestamp: any) => {
  const currentDate = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
  const currentYear = currentDate.getFullYear().toString().slice(2);
  const currentMonth = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  const currentDay = ("0" + currentDate.getDate()).slice(-2);
  const currentHours = ("0" + currentDate.getHours()).slice(-2);
  const currentMinutes = ("0" + currentDate.getMinutes()).slice(-2);
  const formattedDate = `${currentDay}.${currentMonth}.${currentYear}-${currentHours}.${currentMinutes}`;
  return formattedDate;
};

export function timestampToYearsMonths(timestamp: number): string {
  // 123984000
  // Constants
  const secondsInDay = 86400;
  const secondsInMonth = 2629743; // Approximate month length
  const secondsInYear = 31104000; // Approximate year length

  //handling this timestamp here becuse the native behavior dosent allow slider to get to the max date
  if (timestamp === 123984000) {
    timestamp = 124416000;
  }
  // Calculate total days, months, and years
  let totalSeconds = timestamp;
  let years = Math.floor(totalSeconds / secondsInYear);
  totalSeconds -= years * secondsInYear;

  let months = Math.floor(totalSeconds / secondsInMonth);
  totalSeconds -= months * secondsInMonth;

  let days = Math.floor(totalSeconds / secondsInDay);

  let result = "";

  // Building the result string
  if (years > 0) {
    result += years + (years === 1 ? " year" : " years");
  }
  if (months > 0) {
    if (result) {
      result += " ";
    }
    result += months + (months === 1 ? " month" : " months");
  }
  if (days > 0 && !years && !months) {
    result = days + (days === 1 ? " day" : " days");
  } else if (days > 0) {
    if (result) {
      result += " ";
    }
    result += days + (days === 1 ? " day" : " days");
  }

  return result || "0 days";
}

export const formatterWithoutDollar = new Intl.NumberFormat("en-US", {
  style: "decimal",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export function formatDateAndDuration(timestampInSeconds: any) {
  const timestampInMilliseconds = timestampInSeconds * 1000;

  const futureDate = new Date(timestampInMilliseconds);
  const currentDate = new Date();

  const formattedDate = futureDate.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  const differenceInMilliseconds = futureDate.getTime() - currentDate.getTime();

  const differenceInDays = Math.ceil(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  return {
    formattedDate: formattedDate,
    durationInDays: differenceInDays,
  };
}

export const EpochDetailsFormat = (epochDetailsNext: any) => {
  const dateFormat = new Date(Number(epochDetailsNext?.toString()) * 1000);
  const formattedDate = dateFormat.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  return formattedDate;
};

export const getNumberOfDays = (t1: any, t2: any) => {
  var millisecondsPerDay = 1000 * 60 * 60 * 24;

  var differenceInMilliseconds = Math.abs(t2 - t1);

  var differenceInDays = Math.floor(
    differenceInMilliseconds / millisecondsPerDay
  );

  return differenceInDays;
};

export function adjustAmount(
  startTimestamp: any,
  endTimestamp: any,
  amount: any
) {
  let fourYearTimestamp = startTimestamp + 4 * 365 * 24 * 60 * 60 * 1000;

  let adjustedAmount = (endTimestamp / fourYearTimestamp) * amount;

  return formatterWithoutDollar.format(adjustedAmount);
}

export function formatTimeRemaining(startTimestamp: any, endTimestamp: any) {
  let differenceInSeconds = Math.floor((endTimestamp - startTimestamp) / 1000);

  let days = Math.floor(differenceInSeconds / (24 * 60 * 60));
  let hours = Math.floor((differenceInSeconds % (24 * 60 * 60)) / (60 * 60));
  let minutes = Math.floor((differenceInSeconds % (60 * 60)) / 60);
  let seconds = differenceInSeconds % 60;

  let formattedTime = `${days.toString().padStart(2, "0")}:${hours
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return formattedTime;
}

export const getDaysFromSecs = (seconds: any) => {
  return seconds / (60 * 60 * 24);
};

export const getDaysFromTimestamp = (timestamp: any) => {
  const currentTimestamp = Math.floor(Date.now() / 1000);

  const differenceInSeconds = timestamp - currentTimestamp;
  const differenceInDays = Math.ceil(differenceInSeconds / (24 * 3600));
  return differenceInDays;
};


export const getDaysFromTimestampRegex = (timestamp: any) => {
  const timestampPattern = /^\d{10}$/;
  if (!timestampPattern.test(timestamp)) {
    return timestamp; // return the original string unchanged if it's not a timestamp
  }

  const currentTimestamp = Math.floor(Date.now() / 1000);
  const differenceInSeconds = currentTimestamp - timestamp ;
  const differenceInDays = Math.ceil(differenceInSeconds / (24 * 3600));
  const finalDifference = differenceInDays < 0 ? 0 :differenceInDays
  return `${finalDifference} Days`;
  // return differenceInDays
};


export const calculateLockingModalDate = (providedTimestamp: number) => {
  if (providedTimestamp === 123984000) {
    providedTimestamp = 124416000;
  }
  
  const currentTimestamp = Date.now();

  const futureTimestamp = currentTimestamp + providedTimestamp * 1000; // Multiply by 1000 to convert seconds to milliseconds

  const futureDate = new Date(futureTimestamp);

  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "UTC",
    timeZoneName: "short",
  };

  return futureDate.toLocaleString("en-US", options);
};

export function calculateDaysBetweenTimestamps(startTimestamp:any, endTimestamp:any) {
  // Number of seconds in a day
  const secondsPerDay = 86400;
  
  // Calculate the difference in seconds
  const differenceInSeconds = endTimestamp - startTimestamp;
  
  // Convert the difference to days
  const differenceInDays = differenceInSeconds / secondsPerDay;
  
  // Round the number of days to the nearest whole number
  const roundedDays = Math.round(differenceInDays);
  
  return roundedDays;
}