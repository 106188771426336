import DoubleCurrencyLogo from "components/DoubleLogo";
import { BigNumber } from "ethers";
import { useToken } from "hooks/Tokens";
import { usePositionData } from "hooks/pool/usePoolData";
import { useCheckPositionStakingStatus } from "hooks/staking/useCheckHarvestTimeDuration";
import { useGetPendingRewards } from "hooks/staking/useGetPendingRewards";
import { useGetUserStakedPositions } from "hooks/staking/useGetUserStakedPositions";
import {
  useIsStakingStatus,
  useIsStakingStatusDetail,
} from "hooks/staking/useIsStakingStatus";
import { usePool } from "hooks/usePools";
import { useV3PositionFees } from "hooks/useV3PositionFees";
import { useV3PositionFromTokenId } from "hooks/useV3Positions";
import TokenLogos from "pages/Pool/TokenLogo";
import {
  ResponsiveGrid,
  TableRow,
  TableText,
  TokenNameWrapper,
} from "pages/Pool/poolStyle";
import {
  GradientText,
  PoolNameWrapper,
  TokenLogoWrapper,
} from "pages/Pool/styleds";
import { useState } from "react";
import { feeTierPercent } from "utils";
import { formatCurrencyAmount } from "utils/formatCurrencyAmount";
import { formatNumberWithSuffix } from "utils/numbers";
import { unwrappedToken } from "utils/unwrappedToken";

export default function RowMapping(props: any) {
  const { item, navigateToPosition, chainId, aprRewards } = props;
  const tokenBigId = BigNumber.from(item?.id);
  const { data: position } = usePositionData(item?.id.toString());


  const { loading, position: positionDetails } =
    useV3PositionFromTokenId(tokenBigId);

  const token0 = useToken(item?.token0?.id);
  const token1 = useToken(item?.token1?.id);

  const {
    positions: positionsData,
    positions0,
    positions1,
  } = useGetUserStakedPositions(item?.id);

  const stakedPosition0 = !isNaN(parseInt(positions0?.hex, 16));
  const stakedPosition1 = !isNaN(parseInt(positions1?.hex, 16));

  const { pendingRewards } = useGetPendingRewards(item?.id?.toString());
  const { data } = useIsStakingStatusDetail(item?.id?.toString());

  const currency0 = token0 ? unwrappedToken(token0) : undefined;
  const currency1 = token1 ? unwrappedToken(token1) : undefined;
  const [receiveWETH, setReceiveWETH] = useState(false);

  const [poolState, pool] = usePool(
    token0 ?? undefined,
    token1 ?? undefined,
    Number(item?.pool?.feeTier)
  );

  const [feeValue0, feeValue1] = useV3PositionFees(
    pool ?? undefined,
    positionDetails?.tokenId,
    receiveWETH
  );
  console.log("WETH", feeValue0, feeValue1);

  const formatterWithoutDollar = new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });


  const feeValueUpper = feeValue0;
  const feeValueLower = feeValue1;


  return (
    <TableRow onClick={() => navigateToPosition(item)}>
      <ResponsiveGrid>
        <PoolNameWrapper>
          <span
            style={{
              fontSize: 10,
              color: "#868E9B",
              fontWeight: 500,
              cursor: "pointer",
            }}
          >
            {" "}
            #{item?.id}
          </span>{" "}
          &nbsp; &nbsp;
          <TokenLogoWrapper style={{ cursor: "pointer" }}>
            <DoubleCurrencyLogo
              currency0={currency0}
              currency1={currency1}
              size={32}
              margin
            />
          </TokenLogoWrapper>
          <TableText>
            <div>
              <div>
                <div style={{ fontWeight: 500 }}>
                  {item?.token1?.symbol} / {item?.token0?.symbol}
                </div>
                <div
                  style={{ color: "#868E9B", fontSize: 11, fontWeight: 500 }}
                >
                  {feeTierPercent(item?.pool?.feeTier)}
                </div>
              </div>
            </div>
          </TableText>
        </PoolNameWrapper>

        <div style={{ cursor: "pointer" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                fontSize: 14,
                padding: "0.2rem 0px",
              }}
            >
              {data?.lps?.length && data?.lps[0]?.withdrawn
                ? formatNumberWithSuffix(
                    Number(
                      position?.positions[0]?.depositedToken0 -
                        position?.positions[0]?.withdrawnToken0
                    )
                  )
                : !data?.lps?.length
                  ? formatNumberWithSuffix(
                      Number(
                        position?.positions[0]?.depositedToken0 -
                          position?.positions[0]?.withdrawnToken0
                      )
                    )
                  : 0}{" "}
              &nbsp;
              <span style={{ color: "#868E9B" }}>{item?.token0?.symbol}</span>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                fontSize: 14,
                padding: "0.2rem 0px",
              }}
            >
              {data?.lps?.length && data?.lps[0]?.withdrawn
                ? formatNumberWithSuffix(
                    Number(position?.positions[0]?.depositedToken1 - position?.positions[0]?.withdrawnToken1)
                  )
                : !data?.lps?.length
                  ? formatNumberWithSuffix(
                      Number(position?.positions[0]?.depositedToken1- position?.positions[0]?.withdrawnToken1)
                    )
                  : 0}{" "}
              &nbsp;{" "}
              <span style={{ color: "#868E9B" }}> {item?.token1?.symbol} </span>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <GradientText>Stake</GradientText>
          </div>
        </div>

        <div style={{ cursor: "pointer" }}>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                fontSize: 14,
                padding: "0.2rem 0px",
              }}
            >
              {data?.lps?.length && !data?.lps[0]?.withdrawn
                ? formatNumberWithSuffix(
                    Number(position?.positions[0]?.depositedToken0 - position?.positions[0]?.withdrawnToken0)
                  )
                : 0}
              &nbsp;
              <span style={{ color: "#868E9B" }}>{item?.token0?.symbol}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                fontSize: 14,
                padding: "0.2rem 0px",
              }}
            >
              {data?.lps?.length && !data?.lps[0]?.withdrawn
                ? formatNumberWithSuffix(
                    Number(position?.positions[0]?.depositedToken1 - position?.positions[0]?.withdrawnToken1)
                  )
                : 0}
              &nbsp;{" "}
              <span style={{ color: "#868E9B" }}> {item?.token1?.symbol} </span>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <GradientText>Unstake</GradientText>
          </div>
        </div>

        <div style={{ cursor: "pointer" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                fontSize: 14,
                padding: "0.2rem 0px",
              }}
            >
              {formatterWithoutDollar.format(Number(pendingRewards / 10 ** 18))}
              &nbsp;
              <span style={{ color: "#868E9B" }}>ELTK</span>
            </div>
          </div>
        </div>
        <TableText style={{ fontSize: 14, cursor: "pointer" }}>
          {aprRewards && aprRewards?.toFixed(2)}%
        </TableText>

        <div style={{ cursor: "pointer" }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                fontSize: 14,
                padding: "0.2rem 0px",
              }}
            >
              {formatCurrencyAmount(feeValueUpper, 4)} &nbsp;
              <span style={{ color: "#868E9B" }}>{item?.token0?.symbol}</span>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                fontSize: 14,
                padding: "0.2rem 0px",
              }}
            >
              {formatCurrencyAmount(feeValueLower, 4)} &nbsp;
              <span style={{ color: "#868E9B" }}> {item?.token1?.symbol} </span>
            </div>
          </div>
        </div>
      </ResponsiveGrid>
    </TableRow>
  );
}
