import { Currency, Percent, Price, Token } from "@uniswap/sdk-core";
import { Position } from "@uniswap/v3-sdk";
import { useWeb3React } from "@web3-react/core";
import Badge from "components/Badge";
import RangeBadge from "components/Badge/RangeBadge";
import { LightCard } from "components/Card";
import { AutoColumn } from "components/Column";
import DoubleCurrencyLogo from "components/DoubleLogo";
import HoverInlineText from "components/HoverInlineText";
import Loader from "components/Icons/LoadingSpinner";
import CurrencyLogo from "components/Logo/CurrencyLogo";
import { RowBetween, RowFixed } from "components/Row";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "constants/chains";
import { isGqlSupportedChain } from "graphql/data/util";
import { useToken } from "hooks/Tokens";
import useIsTickAtLimit from "hooks/useIsTickAtLimit";
import { usePool } from "hooks/usePools";
import { useV3PositionFees } from "hooks/useV3PositionFees";
import { useEffect, useMemo, useState } from "react";
import { Bound } from "state/mint/v3/actions";
import { useTheme } from "styled-components/macro";
import { ExternalLink, HideSmall, SmallOnly, ThemedText } from "theme";
import { formatCurrencyAmount } from "utils/formatCurrencyAmount";
import { formatTickPrice } from "utils/formatTickPrice";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";
import { unwrappedToken } from "utils/unwrappedToken";
import { ReactComponent as LetsIcon } from "assets/svg/lets-icons_out.svg";

import {
  DAI,
  USDC_MAINNET,
  USDT,
  WBTC,
  WRAPPED_NATIVE_CURRENCY,
} from "../../constants/tokens";
import { PositionListItemProps } from "types";
import {
  DoubleArrow,
  ExtentsText,
  FeeTierText,
  GradientText,
  Label,
  PoolInfo,
  PositionWrapper,
  PrimaryPositionIdData,
  RangeLineItem,
  RangeText,
  MidleLineContainer,
  LightCardText,
  GradientPercent,
  PinkMark,
  FeeTierTextPosition,
} from "./styled";
import { truncateFormatPriceImpact } from "components/CurrencyInputPanel/limitFiatValue";
import { MouseoverTooltip } from "components/Tooltip";
import { useNavigate } from "react-router";
import { getPositionData } from "graphql/thegraph/PoolQuery";
import { useGetUserStakedPositions } from "hooks/staking/useGetUserStakedPositions";
import { useGetPendingRewards } from "hooks/staking/useGetPendingRewards";
import {
  useIsStakingStatus,
  useIsStakingStatusDetail,
} from "hooks/staking/useIsStakingStatus";
import { useGetPoolRewardApr } from "hooks/staking/useGetPoolRewardApr";
import { useGetPoolAllocPoints } from "hooks/staking/useGetPoolAllocPoints";
import { usePoolId } from "hooks/pool/usePoolId";
import { useGetPoolAddressFromTokenIds } from "hooks/staking/useCheckHarvestTimeDuration";
import { FeeTierSpan } from "pages/Pool/poolStyle";
import { usePositionData } from "hooks/pool/usePoolData";
import { formatNumberWithSuffix } from "utils/numbers";

export const fetchPositionData = async (
  tokenId: string,
  account: string | undefined,
  isStaked?: boolean
) => {
  try {
    const response = await getPositionData(tokenId, account!, isStaked);
    return response;
  } catch (error) {
    console.error("Error fetching liquidity data for pool", ":", error);
  }
};

function LinkedCurrency({
  chainId,
  currency,
}: {
  chainId?: number;
  currency?: Currency;
}) {
  const address = (currency as Token)?.address;

  if (typeof chainId === "number" && address) {
    return (
      <ExternalLink href={getTokenLink(chainId, address)}>
        <RowFixed>
          <CurrencyLogo
            currency={currency}
            size="20px"
            style={{ marginRight: "0.5rem" }}
          />
          <ThemedText.BodySecondary>
            {currency?.symbol} <LetsIcon />
          </ThemedText.BodySecondary>
        </RowFixed>
      </ExternalLink>
    );
  }

  return (
    <RowFixed>
      <CurrencyLogo
        currency={currency}
        size="20px"
        style={{ marginRight: "0.5rem" }}
      />
      <ThemedText.BodySecondary>
        {currency?.symbol} <LetsIcon />
      </ThemedText.BodySecondary>
    </RowFixed>
  );
}

const getTokenLink = (chainId: SupportedChainId, address: string) => {
  if (isGqlSupportedChain(chainId)) {
    const chainName = CHAIN_IDS_TO_NAMES[chainId];
    return `${window.location.origin}/#/tokens/${chainName}/${address}`;
  } else {
    return getExplorerLink(chainId, address, ExplorerDataType.TOKEN);
  }
};

function getRatio(
  lower: Price<Currency, Currency>,
  current: Price<Currency, Currency>,
  upper: Price<Currency, Currency>
) {
  try {
    if (!current.greaterThan(lower)) {
      return 100;
    } else if (!current.lessThan(upper)) {
      return 0;
    }

    const a = Number.parseFloat(lower.toSignificant(15));
    const b = Number.parseFloat(upper.toSignificant(15));
    const c = Number.parseFloat(current.toSignificant(15));

    const ratio = Math.floor(
      (1 /
        ((Math.sqrt(a * b) - Math.sqrt(b * c)) / (c - Math.sqrt(b * c)) + 1)) *
        100
    );

    if (ratio < 0 || ratio > 100) {
      throw Error("Out of range");
    }

    return ratio;
  } catch {
    return undefined;
  }
}

export function getPriceOrderingFromPositionForUI(position?: Position): {
  priceLower?: Price<Token, Token>;
  priceUpper?: Price<Token, Token>;
  quote?: Token;
  base?: Token;
} {
  if (!position) {
    return {};
  }

  const token0 = position.amount0.currency;
  const token1 = position.amount1.currency;

  // if token0 is a dollar-stable asset, set it as the quote token
  const stables = [DAI, USDC_MAINNET, USDT];
  if (stables.some((stable) => stable.equals(token0))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    };
  }

  // if token1 is an ETH-/BTC-stable asset, set it as the base token
  const bases = [...Object.values(WRAPPED_NATIVE_CURRENCY), WBTC];
  if (bases.some((base) => base && base.equals(token1))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    };
  }

  // if both prices are below 1, invert
  if (position.token0PriceUpper.lessThan(1)) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1,
    };
  }

  // otherwise, just return the default
  return {
    priceLower: position.token0PriceLower,
    priceUpper: position.token0PriceUpper,
    quote: token1,
    base: token0,
  };
}

export default function PositionListItem({
  token0: token0Address,
  token1: token1Address,
  tokenId,
  fee: feeAmount,
  liquidity,
  tickLower,
  tickUpper,
}: PositionListItemProps) {
  const theme = useTheme();
  const { chainId, account } = useWeb3React();
  const [aprRewards, setAPrRewards] = useState<any>(null);
  const { data: positionData } = usePositionData(tokenId.toString());

  const { data: poolAddress } = useGetPoolAddressFromTokenIds(
    token0Address?.toLowerCase(),
    token1Address?.toLowerCase(),
    feeAmount?.toString(),
    chainId
  );
  const { data: positionStakedData } = usePositionData(tokenId?.toString());

  const token0 = useToken(token0Address);
  const token1 = useToken(token1Address);

  const currency0 = token0 ? unwrappedToken(token0) : undefined;
  const currency1 = token1 ? unwrappedToken(token1) : undefined;

  const { pendingRewards } = useGetPendingRewards(tokenId?.toString());

  const { poolId: poolIdPool, loading: poolIdLoading } = usePoolId(
    poolAddress?.pools[0]?.id
  );

  const { poolRewards: totalPoolRewardsAllocPoints } = useGetPoolRewardApr();
  const { poolRewards: poolAllocPoints } = useGetPoolAllocPoints(poolIdPool);

  useEffect(() => {
    if (poolAllocPoints && totalPoolRewardsAllocPoints) {
      const rewards = (poolAllocPoints / totalPoolRewardsAllocPoints) * 100;
      setAPrRewards(rewards);
    }
  }, [totalPoolRewardsAllocPoints, poolAllocPoints]);

  // construct Position from details returned
  const [, pool] = usePool(
    currency0 ?? undefined,
    currency1 ?? undefined,
    Number(feeAmount)
  );

  const position = useMemo(() => {
    if (pool) {
      return new Position({
        pool,
        liquidity: liquidity.toString(),
        tickLower:
          typeof tickLower === "number" ? tickLower : parseInt(tickLower),
        tickUpper:
          typeof tickUpper === "number" ? tickUpper : parseInt(tickUpper),
      });
    }
    return undefined;
  }, [liquidity, pool, tickLower, tickUpper]);

  const tickAtLimit = useIsTickAtLimit(
    feeAmount,
    typeof tickLower === "number" ? tickLower : parseInt(tickLower),
    typeof tickUpper === "number" ? tickUpper : parseInt(tickUpper)
  );

  // prices
  const { priceLower, priceUpper, quote, base } =
    getPriceOrderingFromPositionForUI(position);

  const currencyQuote = quote && unwrappedToken(quote);
  const currencyBase = base && unwrappedToken(base);

  // check if price is within range
  const outOfRange: boolean = pool
    ? pool.tickCurrent < tickLower || pool.tickCurrent >= tickUpper
    : false;

  // const positionSummaryLink = "/pools/single/" + tokenId + / details;
  const removed = liquidity?.eq(0);

  //rgor
  const inverted = token1 ? base?.equals(token1) : undefined;

  const ratio = useMemo(() => {
    return priceLower && pool && priceUpper
      ? getRatio(
          inverted ? priceUpper.invert() : priceLower,
          pool.token0Price,
          inverted ? priceLower.invert() : priceUpper
        )
      : undefined;
  }, [inverted, pool, priceLower, priceUpper]);

  const [feeValue0, feeValue1] = useV3PositionFees(
    pool ?? undefined,
    tokenId,
    false
  );

  const feeValueUpper = inverted ? feeValue0 : feeValue1;
  const feeValueLower = inverted ? feeValue1 : feeValue0;

  const navigate = useNavigate();

  const navigateToPosition = (item: any) => {
    navigate(`/positions/${tokenId}`);
  };

  const { data } = useIsStakingStatusDetail(tokenId?.toString());

  if(outOfRange || removed){
    return <></>
  }



  return (
  
    <PositionWrapper>
      <div onClick={navigateToPosition} style={{ cursor: "pointer" }}>
        <RowBetween>
          <PrimaryPositionIdData>
            <DoubleCurrencyLogo
              currency0={currencyBase}
              currency1={currencyQuote}
              size={36}
              margin
            />
            <ThemedText.SubHeaderDeprecated style={{ height: 35 }}>
              &nbsp;
              <span>
                {currencyQuote?.symbol}&nbsp;/&nbsp;{currencyBase?.symbol}{" "}
                &nbsp;{" "}
                {priceLower && priceUpper ? (
                  <span
                    style={{
                      background:
                        "linear-gradient(194deg, #d96dff 3.9%, #ad00ff 98.12%)",
                      fontSize: 10,
                      fontWeight: 300,
                      height: 20,
                      padding: "5px 18px",
                      borderRadius: 12,
                      position: "relative",
                      top: "-7px",
                    }}
                  >
                    {new Percent(feeAmount, 1_000_000).toSignificant()}% Fee
                    tier
                  </span>
                ) : (
                  <></>
                )}{" "}
                &nbsp;
                {priceLower && priceUpper ? (
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      position: "relative",
                      top: "-7px",
                    }}
                  >
                    {" "}
                    #{tokenId.toString()}
                  </span>
                ) : (
                  <></>
                )}
              </span>
              <br />
              {priceLower && priceUpper ? (
                <RangeLineItem>
                  <RangeText
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <ExtentsText>
                      <div>Mins: </div>
                    </ExtentsText>
                    <div>
                      <span>
                        {formatTickPrice({
                          price: priceLower,
                          atLimit: tickAtLimit,
                          direction: Bound.LOWER,
                        })}{" "}
                      </span>
                      <HoverInlineText text={currencyQuote?.symbol} /> per{" "}
                      <HoverInlineText text={currencyBase?.symbol ?? ""} />
                    </div>
                  </RangeText>{" "}
                  <HideSmall>
                    <DoubleArrow>↔</DoubleArrow>{" "}
                  </HideSmall>
                  <SmallOnly>
                    <DoubleArrow>↔</DoubleArrow>{" "}
                  </SmallOnly>
                  <RangeText
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <ExtentsText>
                      <div>Max:</div>
                    </ExtentsText>
                    <div>
                      <span>
                        {formatTickPrice({
                          price: priceUpper,
                          atLimit: tickAtLimit,
                          direction: Bound.UPPER,
                        })}{" "}
                      </span>
                      <HoverInlineText text={currencyQuote?.symbol} /> per{" "}
                      <HoverInlineText
                        maxCharacters={10}
                        text={currencyBase?.symbol}
                      />
                    </div>
                  </RangeText>
                </RangeLineItem>
              ) : (
                <Loader />
              )}
            </ThemedText.SubHeaderDeprecated>
          </PrimaryPositionIdData>
          <RangeBadge removed={removed} inRange={!outOfRange} />
        </RowBetween>
        <MidleLineContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div>
              <span
                style={{
                  font: "Inter",
                  fontWeight: 600,
                  fontSize: 14,
                  color: "#868E9B",
                }}
              >
                Rewards APR:{" "}
              </span>
              <GradientText
                style={{ font: "Inter", fontWeight: 600, fontSize: 14 }}
              >
                {aprRewards?.toFixed(2) || 0}%
              </GradientText>
            </div>{" "}
            &nbsp; &nbsp;
            <div>
              <span
                style={{
                  font: "Inter",
                  fontWeight: 600,
                  fontSize: 14,
                  color: "#868E9B",
                }}
              >
                Total Reward:{" "}
              </span>
              <GradientText
                style={{ font: "Inter", fontWeight: 600, fontSize: 14 }}
              >
                {pendingRewards || 0} ELTK
              </GradientText>
            </div>
          </div>
        </MidleLineContainer>

        <PoolInfo style={{}}>
          <div style={{ flex: 1 }}>
            <AutoColumn gap="md" style={{ width: "100%" }}>
              <LightCard
                padding="12px 16px"
                style={{
                  background: "#121B1E",
                  border: "none",
                  padding: "16px 24px",
                }}
              >
                <AutoColumn gap="md">
                  <Label style={{ marginBottom: "14px" }}>
                    <LightCardText>Liquidity</LightCardText>
                  </Label>
                </AutoColumn>
                <AutoColumn gap="md">
                  <RowBetween>
                    <LinkedCurrency
                      chainId={chainId}
                      currency={currencyQuote}
                    />
                    <RowFixed>
                      <MouseoverTooltip
                        text={
                          inverted
                            ? position?.amount0.toSignificant(4)
                            : position?.amount1.toSignificant(4)
                        }
                        disabled={false}
                      >
                        <ThemedText.BodySecondary>
                          {inverted
                            ? truncateFormatPriceImpact(
                                position?.amount0.toSignificant(4)
                              )
                            : truncateFormatPriceImpact(
                                position?.amount1.toSignificant(4)
                              )}
                        </ThemedText.BodySecondary>
                      </MouseoverTooltip>
                      {typeof ratio === "number" && !removed ? (
                        <Badge
                          style={{
                            marginLeft: "10px",
                            background:
                              "linear-gradient(191deg, #203035 5.4%, #122933 92.07%)",
                          }}
                        >
                          <GradientPercent>
                            <div>{inverted ? ratio : 100 - ratio}%</div>
                          </GradientPercent>
                        </Badge>
                      ) : null}
                    </RowFixed>
                  </RowBetween>
                  <RowBetween>
                    <LinkedCurrency chainId={chainId} currency={currencyBase} />
                    <RowFixed>
                      <MouseoverTooltip
                        text={
                          inverted
                            ? position?.amount1.toSignificant(4)
                            : position?.amount0.toSignificant(4)
                        }
                        disabled={false}
                      >
                        <ThemedText.BodySecondary>
                          {inverted
                            ? truncateFormatPriceImpact(
                                position?.amount1.toSignificant(4)
                              )
                            : truncateFormatPriceImpact(
                                position?.amount0.toSignificant(4)
                              )}
                        </ThemedText.BodySecondary>
                      </MouseoverTooltip>
                      {typeof ratio === "number" ? (
                        <Badge
                          style={{
                            marginLeft: "10px",
                            background:
                              "linear-gradient(191deg, #203035 5.4%, #122933 92.07%)",
                          }}
                        >
                          <GradientPercent>
                            <div>{inverted ? 100 - ratio : ratio}%</div>
                          </GradientPercent>
                        </Badge>
                      ) : null}
                    </RowFixed>
                  </RowBetween>
                </AutoColumn>
              </LightCard>
            </AutoColumn>
          </div>

          <div style={{ flex: 1 }}>
            <AutoColumn gap="md" style={{ width: "100%" }}>
              <LightCard
                padding="12px 16px"
                style={{
                  background: "#121B1E",
                  border: "none",
                  padding: "16px 24px",
                }}
              >
                <AutoColumn gap="md">
                  <AutoColumn gap="md">
                    <Label style={{ marginBottom: "14px" }}>
                      <LightCardText>Unclaimed Fees</LightCardText>
                    </Label>
                  </AutoColumn>
                </AutoColumn>
                <AutoColumn gap="md">
                  <RowBetween>
                    <RowFixed>
                      <CurrencyLogo
                        currency={feeValueUpper?.currency}
                        size="20px"
                        style={{ marginRight: "0.5rem" }}
                      />
                      <ThemedText.BodySecondary>
                        {feeValueUpper?.currency?.symbol}
                      </ThemedText.BodySecondary>
                    </RowFixed>
                    <RowFixed>
                      <MouseoverTooltip
                        text={formatCurrencyAmount(feeValueUpper, 4)}
                        disabled={false}
                      >
                        <ThemedText.BodySecondary
                          style={{ padding: "4px 6px" }}
                        >
                          {feeValueUpper
                            ? truncateFormatPriceImpact(
                                formatCurrencyAmount(feeValueUpper, 4)
                              )
                            : "-"}
                        </ThemedText.BodySecondary>
                      </MouseoverTooltip>
                    </RowFixed>
                  </RowBetween>
                  <RowBetween>
                    <RowFixed>
                      <CurrencyLogo
                        currency={feeValueLower?.currency}
                        size="20px"
                        style={{ marginRight: "0.5rem" }}
                      />
                      <ThemedText.BodySecondary>
                        {feeValueLower?.currency?.symbol}
                      </ThemedText.BodySecondary>
                    </RowFixed>
                    <RowFixed>
                      <MouseoverTooltip
                        text={formatCurrencyAmount(feeValueLower, 4)}
                        disabled={false}
                      >
                        <ThemedText.BodySecondary
                          style={{ padding: "4px 6px" }}
                        >
                          {feeValueLower
                            ? truncateFormatPriceImpact(
                                formatCurrencyAmount(feeValueLower, 4)
                              )
                            : "-"}
                        </ThemedText.BodySecondary>
                      </MouseoverTooltip>
                    </RowFixed>
                  </RowBetween>
                </AutoColumn>
              </LightCard>
            </AutoColumn>
          </div>

          <div style={{ flex: 1 }}>
            <AutoColumn gap="md" style={{ width: "100%" }}>
              <LightCard
                padding="12px 16px"
                style={{
                  background: "#121B1E",
                  border: "none",
                  padding: "16px 24px",
                }}
              >
                <AutoColumn gap="md">
                  <AutoColumn gap="md">
                    <Label style={{ marginBottom: "14px" }}>
                      <LightCardText>Staked</LightCardText>
                    </Label>
                  </AutoColumn>
                </AutoColumn>
                <AutoColumn gap="md">
                  <RowBetween>
                    <RowFixed>
                      <CurrencyLogo
                        currency={feeValueUpper?.currency}
                        size="20px"
                        style={{ marginRight: "0.5rem" }}
                      />
                      <ThemedText.BodySecondary>
                        {feeValueUpper?.currency?.symbol}
                      </ThemedText.BodySecondary>
                    </RowFixed>
                    <RowFixed>
                      <MouseoverTooltip text={""} disabled={false}>
                        <ThemedText.BodySecondary
                          style={{ padding: "4px 6px" }}
                        >
                          {data?.lps?.length && !data?.lps[0]?.withdrawn
                            ? formatNumberWithSuffix(
                                Number(
                                  positionData?.positions[0]?.depositedToken0 -
                                  positionData?.positions[0]?.withdrawnToken0
                                )
                              )
                            : 0}
                        </ThemedText.BodySecondary>
                      </MouseoverTooltip>
                    </RowFixed>
                  </RowBetween>
                  <RowBetween>
                    <RowFixed>
                      <CurrencyLogo
                        currency={feeValueLower?.currency}
                        size="20px"
                        style={{ marginRight: "0.5rem" }}
                      />
                      <ThemedText.BodySecondary>
                        {feeValueLower?.currency?.symbol}
                      </ThemedText.BodySecondary>
                    </RowFixed>
                    <RowFixed>
                      <MouseoverTooltip text={""} disabled={false}>
                        <ThemedText.BodySecondary
                          style={{ padding: "4px 6px" }}
                        >
                          {data?.lps?.length && !data?.lps[0]?.withdrawn
                            ? formatNumberWithSuffix(
                                Number(
                                  positionData?.positions[0]?.depositedToken1 -
                                  positionData?.positions[0]?.withdrawnToken1
                                )
                              )
                            : 0}
                        </ThemedText.BodySecondary>
                      </MouseoverTooltip>
                    </RowFixed>
                  </RowBetween>
                  {/* <RowBetween>
                    <RowFixed>Ever Staked</RowFixed>
                    <RowFixed>{data?.lp === null ? "True" : "False"}</RowFixed>
                  </RowBetween> */}
                </AutoColumn>
              </LightCard>
            </AutoColumn>
          </div>
        </PoolInfo>
      </div>
    </PositionWrapper>
  );
}
