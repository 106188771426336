import { Link } from "react-router-dom";
import styled from "styled-components";
import { MEDIA_WIDTHS, ThemedText } from "theme";
import { Z_INDEX } from "theme/zIndex";

export const LinkRow = styled(Link)`
  align-items: center;
  display: flex;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.textPrimary};
  padding: 16px;
  text-decoration: none;
  font-weight: 500;

  & > div:not(:first-child) {
    text-align: center;
  }
  :hover {
    background-color: ${({ theme }) => theme.hoverDefault};
  }

  @media screen and (min-width: ${MEDIA_WIDTHS.deprecated_upToSmall}px) {
    /* flex-direction: row; */
  }

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    row-gap: 8px;
  `};
`;

export const DataLineItem = styled.div`
  font-size: 14px;
`;

export const RangeLineItem = styled(DataLineItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  width: 100%;
  
`;

export const DoubleArrow = styled.span`
  font-size: 12px;
  margin: 0 2px;
  color: ${({ theme }) => theme.textTertiary};
`;

export const RangeText = styled(ThemedText.Caption)`
  font-size: 12px !important;
  word-break: break-word;
  padding: 0.25rem 0.25rem;
  border-radius: 8px;
`;

export const FeeTierText = styled(ThemedText.UtilityBadge)`
  font-size: 12px !important;
  margin-left: 14px !important;
`;


export const FeeTierTextPosition = styled(ThemedText.UtilityBadge)`
  font-size: 12px !important;

`;

export const MidleLineContainer = styled.div`
  display: flex;
  align-items: baseline;
   
`;

export const ExtentsText = styled(ThemedText.Caption)`
  color: ${({ theme }) => theme.textTertiary};
  display: inline-block;
  line-height: 16px;
  margin-right: 4px !important;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    display: none;
  `};
`;

export const PrimaryPositionIdData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-right: 8px;
  }
`;

export const Label = styled(({ end, ...props }) => (
  <ThemedText.DeprecatedLabel {...props} />
))<{ end?: boolean }>`
  display: flex;
  font-size: 16px;
  justify-content: ${({ end }) => (end ? "flex-end" : "flex-start")};
  align-items: start;
`;

export const PoolInfo = styled.div`
  width: 100%;
  display: flex;
  gap: 14px;
  /* margin-top: 20px; */
  @media screen and (max-width: ${MEDIA_WIDTHS.deprecated_upToSmall}px) {
    flex-direction: column;
  }
`;
export const LightCardText = styled.div`
color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 27px */
`;


export const GradientPercent = styled.div`
background: var(--Gradient, linear-gradient(191deg, #A1EEFF 5.4%, #029AF0 92.07%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 21px */
`;

export const PinkMark = styled.div`

display: flex;
justify-content: center;
align-items: center;
border-radius: 20px;
background:  linear-gradient(194deg, #D96DFF 3.9%, #AD00FF 98.12%);
color: white;
padding-left: 4px;
height: 20px;
width: 100px;
font-size: 10px;
padding-right: 4px; 

@media (max-width: 750px) {  
  width: 75px;
 }

`;




export const PositionWrapper = styled.div`
  position: relative;
  --border-width: 2px;
  --border-radius: 12px;

  background: linear-gradient(180deg, #92d5e3 0%, #091920 100%);
  border: 0;
  padding: 24px 27px;
  position: relative;
  border-radius: var(--border-radius);
  margin-bottom:20px;

  z-index: ${Z_INDEX.default};
  &::before {
    content: "";
    display: block;
    height: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    width: calc(
      100% - (var(--border-width) * 2)
    ); /* Corrected the syntax here */
    background: ${({ theme }) => theme.backgroundSurface};
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: ${Z_INDEX.negative};
  }
`;


export const GradientText = styled.span`
  background: linear-gradient(90.8deg, #a2eeff 7.28%, #029af0 89.82%);
  -webkit-background-clip: text;
  color: transparent;
  display: inline;
  font-size: 10px;
  font-weight: bold;
  gap: 10px;
  margin: 4px;
  cursor: pointer;
  text-decoration: none;
`;