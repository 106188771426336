import { BigNumber } from "@ethersproject/bignumber";
import type { TransactionResponse } from "@ethersproject/providers";
import { formatPrice, NumberType } from "@uniswap/conedison/format";
import {
  Currency,
  CurrencyAmount,
  Fraction,
  Price,
  Token,
} from "@uniswap/sdk-core";
import { NonfungiblePositionManager, Pool, Position } from "@uniswap/v3-sdk";
import { useWeb3React } from "@web3-react/core";
import Badge from "components/Badge";
import { ButtonOutlined, ButtonPrimary } from "components/Button";
import { LightCard } from "components/Card";
import { AutoColumn } from "components/Column";
import CurrencyLogo from "components/Logo/CurrencyLogo";
import { RowBetween, RowFixed } from "components/Row";
import { Dots } from "components/swap/styleds";
import Toggle from "components/Toggle";
import {
  CHAIN_IDS_TO_NAMES,
  isSupportedChainLightLink,
  SupportedChainId,
} from "constants/chains";
import { isGqlSupportedChain } from "graphql/data/util";
import { useCurrency, useToken } from "hooks/Tokens";
import { useV3NFTPositionManagerContract } from "hooks/useContract";
import useIsTickAtLimit from "hooks/useIsTickAtLimit";
import { PoolState, usePool } from "hooks/usePools";
import useStablecoinPrice from "hooks/useStablecoinPrice";
import { useV3PositionFees } from "hooks/useV3PositionFees";
import { useV3PositionFromTokenId } from "hooks/useV3Positions";
import { useSingleCallResult } from "lib/hooks/multicall";
import useNativeCurrency from "lib/hooks/useNativeCurrency";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useIsTransactionPending,
  useTransactionAdder,
} from "state/transactions/hooks";
import { useTheme } from "styled-components/macro";
import { ExternalLink, ThemedText } from "theme";
import { currencyId } from "utils/currencyId";
import { formatCurrencyAmount } from "utils/formatCurrencyAmount";
import { unwrappedToken } from "utils/unwrappedToken";

import RangeBadge from "../../components/Badge/RangeBadge";
import {
  fetchPositionData,
  getPriceOrderingFromPositionForUI,
} from "../../components/PositionListItem";
import { TransactionType } from "../../state/transactions/types";
import { calculateGasMargin } from "../../utils/calculateGasMargin";
import { ExplorerDataType, getExplorerLink } from "../../utils/getExplorerLink";
import {
  NFTCanvas,
  NFTGrid,
  NFTImage,
  PositionPageButtonPrimary,
  PositionPageWrapper,
  ResponsiveButtonConfirmed,
  DarkCardGradient,
  PoolCardsContainer,
  GradientConteinerLeft,
  DarkContainerRight,
  CalimContainer,
  ElektrikStakedContainer,
  PoolBadgeContainer,
  WalletContinerGrid,
  PinkBageContainer,
  LabelPosition,
} from "./positionPage.styled";
import {
  BottomSectionWrapper,
  HeaderContentWrapper,
  LeftSection,
  PairDetails,
  PairIconsWithName,
  PoolDetailPageWrapper,
  PoolPageContentWrapperDetails,
  RightSection,
  StackedIcons,
  TopSectionWrapper,
  GradientText,
  GreySubText,
  GreySubTextV2,
  TokenTextTitle,
  WalletLink,
  LeftBorderContainer,
  RightTokenLogo,
  BlurDiv,
  TokenData,
  HeaderWrapperSinglePool,
  TokenDelta,
  CompositionTableWrapperStake,
  TopSectionWrapperPosition,
  BackLogoDiv,
  BackLogoGradientInnerDiv,
  WrappingDiv,
  WrappingDivPool,
  CompositionTableWrapperStakeChart,
} from "./poolStyle";
import { CopyText } from "components/AccountDrawer/AuthenticatedHeader";
import TokenLogos from "./TokenLogo";
import { feeTierPercent, shortenAddress } from "utils";
import { useHandleStake } from "hooks/staking/useHandleStake";
import { useHandleUnStake } from "hooks/staking/useHandleUnstake";
import { useClaimStakingRewards } from "hooks/staking/useClaimStakingRewards";
import { useGetUserStakedPositions } from "hooks/staking/useGetUserStakedPositions";
import { ReactComponent as ElektrikIcon } from "assets/svg/elektriksmal.svg";
import { ReactComponent as LetsIcon } from "assets/svg/lets-icons_out.svg";
import { useGetPoolAddressFromTOkenId } from "hooks/staking/useGetPoolAddressFromTokenId";
import { useGetPendingRewards } from "hooks/staking/useGetPendingRewards";
import {
  checkPositionIsStakedOrNot,
  checkPositionIsStakedOrNotDetails,
  useCheckHarvestTimeDuration,
} from "hooks/staking/useCheckHarvestTimeDuration";
import {
  fetchUserRewardsDepositTimestamp,
  useSinglePoolData,
} from "graphql/thegraph/PoolQuery";
import { useAppDispatch } from "state/hooks";
import {
  StyledArrowLeftChevron,
  StyledChevronArrowLeft,
  StyledHistoryLink,
} from "components/NavigationTabs/styled";
import { ReactComponent as ElektrikLogo } from "assets/svg/elektrik_logo.svg";
import {
  fetchUserLiquidityPositions,
  getChainAddresses,
} from "@eltk/staking-sdk";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { ApprovalState, useApproveCallback } from "hooks/useApproveCallback";
import { usePoolId } from "hooks/pool/usePoolId";
import { useGetPoolDetails } from "hooks/pool/useGetPoolDetails";
import DoubleCurrencyLogo from "components/DoubleLogo";
import DensityChart from "components/DensityChart/AreaChart";
import { LoaderV2 } from "components/Icons/LoadingSpinner";
import { useGetUsdOraclePrice } from "hooks/oracle/useGetUsdOraclePrice";
import { useGetPoolRewardApr } from "hooks/staking/useGetPoolRewardApr";
import { useGetPoolAllocPoints } from "hooks/staking/useGetPoolAllocPoints";
import { useCheckWhitelisted } from "hooks/pool/useCheckWhitelisted";
import TransactionConfirmationModal, {
  ConfirmationModalContent,
} from "components/TransactionConfirmationModal";
import { ReactComponent as CopyIcon } from "assets/svg/copy.svg";
import { useIsStakingStatus } from "hooks/staking/useIsStakingStatus";
import {
  formatter,
  formatterWithoutDollar,
  getDaysFromSecs,
  getDaysFromTimestamp,
  getDaysFromTimestampRegex,
} from "utils/locking";
import { LoadingBubble } from "components/Tokens/loading";
import { formatNumberWithSuffix } from "utils/numbers";
import LPDCHART from "components/DensityChart/LPDchart";
import { getExplorerPrefix } from "constants/utilsConstants";

const getTokenLink = (chainId: SupportedChainId, address: string) => {
  if (isGqlSupportedChain(chainId)) {
    const chainName = CHAIN_IDS_TO_NAMES[chainId];
    return `${window.location.origin}/#/tokens/${chainName}/${address}`;
  } else {
    return getExplorerLink(chainId, address, ExplorerDataType.TOKEN);
  }
};

function LinkedCurrency({
  chainId,
  currency,
}: {
  chainId?: number;
  currency?: Currency;
}) {
  const address = (currency as Token)?.address;

  if (typeof chainId === "number" && address) {
    return (
      <ExternalLink href={getTokenLink(chainId, address)}>
        <RowFixed>
          <CurrencyLogo
            currency={currency}
            size="25px"
            style={{
              marginRight: "15px",
              padding: "11px",
              backgroundColor: "#112C35",
              borderRadius: "50%",
            }}
          />
          <ThemedText.DeprecatedButton>
            {currency?.symbol} ↗
          </ThemedText.DeprecatedButton>
        </RowFixed>
      </ExternalLink>
    );
  }

  return (
    <RowFixed>
      <CurrencyLogo
        currency={currency}
        size="25px"
        style={{
          marginRight: "15px",
          padding: "11px",
          backgroundColor: "#112C35",
          borderRadius: "50%",
        }}
      />
      <ThemedText.DeprecatedButton>
        {currency?.symbol}
      </ThemedText.DeprecatedButton>
    </RowFixed>
  );
}

function LinkedCurrencyDeprecated({
  chainId,
  currency,
}: {
  chainId?: number;
  currency?: Currency;
}) {
  const address = (currency as Token)?.address;

  if (typeof chainId === "number" && address) {
    return (
      <RowFixed>
        <CurrencyLogo
          currency={currency}
          size="25px"
          style={{
            marginRight: "15px",
            padding: "11px",
            backgroundColor: "#112C35",
            borderRadius: "50%",
          }}
        />
        <ThemedText.DeprecatedButton>
          {currency?.symbol}
        </ThemedText.DeprecatedButton>
      </RowFixed>
    );
  }

  return (
    <RowFixed>
      <CurrencyLogo
        currency={currency}
        size="25px"
        style={{
          marginRight: "15px",
          padding: "11px",
          backgroundColor: "#112C35",
          borderRadius: "50%",
        }}
      />
      <ThemedText.DeprecatedButton>
        {currency?.symbol}
      </ThemedText.DeprecatedButton>
    </RowFixed>
  );
}

function getRatio(
  lower: Price<Currency, Currency>,
  current: Price<Currency, Currency>,
  upper: Price<Currency, Currency>
) {
  try {
    if (!current.greaterThan(lower)) {
      return 100;
    } else if (!current.lessThan(upper)) {
      return 0;
    }

    const a = Number.parseFloat(lower.toSignificant(15));
    const b = Number.parseFloat(upper.toSignificant(15));
    const c = Number.parseFloat(current.toSignificant(15));

    const ratio = Math.floor(
      (1 /
        ((Math.sqrt(a * b) - Math.sqrt(b * c)) / (c - Math.sqrt(b * c)) + 1)) *
        100
    );

    if (ratio < 0 || ratio > 100) {
      throw Error("Out of range");
    }

    return ratio;
  } catch {
    return undefined;
  }
}

function getSnapshot(
  src: HTMLImageElement,
  canvas: HTMLCanvasElement,
  targetHeight: number
) {
  const context = canvas.getContext("2d");

  if (context) {
    let { width, height } = src;

    const ratio = width / height;
    height = targetHeight;
    width = Math.round(ratio * targetHeight);

    // Ensure crispness at high DPIs
    canvas.width = width * devicePixelRatio;
    canvas.height = height * devicePixelRatio;
    canvas.style.width = width + "px";
    canvas.style.height = height + "px";
    context.scale(devicePixelRatio, devicePixelRatio);

    context.clearRect(0, 0, width, height);
    context.drawImage(src, 0, 0, width, height);
  }
}

function NFT({
  image,
  height: targetHeight,
}: {
  image: string;
  height: number;
}) {
  const [animate, setAnimate] = useState(false);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  return (
    <NFTGrid
      onMouseEnter={() => {
        setAnimate(true);
      }}
      onMouseLeave={() => {
        // snapshot the current frame so the transition to the canvas is smooth
        if (imageRef.current && canvasRef.current) {
          getSnapshot(imageRef.current, canvasRef.current, targetHeight);
        }
        setAnimate(false);
      }}
    >
      <NFTCanvas ref={canvasRef} />
      <NFTImage
        ref={imageRef}
        src={image}
        hidden={!animate}
        onLoad={() => {
          // snapshot for the canvas
          if (imageRef.current && canvasRef.current) {
            getSnapshot(imageRef.current, canvasRef.current, targetHeight);
          }
        }}
      />
    </NFTGrid>
  );
}

const useInverter = ({
  priceLower,
  priceUpper,
  quote,
  base,
  invert,
}: {
  priceLower?: Price<Token, Token>;
  priceUpper?: Price<Token, Token>;
  quote?: Token;
  base?: Token;
  invert?: boolean;
}): {
  priceLower?: Price<Token, Token>;
  priceUpper?: Price<Token, Token>;
  quote?: Token;
  base?: Token;
} => {
  return {
    priceUpper: invert ? priceLower?.invert() : priceUpper,
    priceLower: invert ? priceUpper?.invert() : priceLower,
    quote: invert ? base : quote,
    base: invert ? quote : base,
  };
};

export function PositionPageUnsupportedContent() {
  return (
    <PositionPageWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <ThemedText.HeadlineLarge style={{ marginBottom: "8px" }}>
          <div>Position unavailable</div>
        </ThemedText.HeadlineLarge>
        <ThemedText.BodyPrimary style={{ marginBottom: "32px" }}>
          <div>
            To view a position, you must be connected to the network it belongs
            to.
          </div>
        </ThemedText.BodyPrimary>
        <PositionPageButtonPrimary as={Link} to="/pools" width="fit-content">
          <div>Back to Pools</div>
        </PositionPageButtonPrimary>
      </div>
    </PositionPageWrapper>
  );
}

export default function PositionPage() {
  const { chainId } = useWeb3React();
  if (isSupportedChainLightLink(chainId)) {
    return <PositionPageContent />;
  } else {
    return <PositionPageUnsupportedContent />;
  }
}

function PositionPageContent() {
  const [isStaked, setIsStaked] = useState(false);
  const [whitelistedPools, setWhitelisted] = useState<any>(false);
  const [aprRewards, setAPrRewards] = useState<any>(null);
  const [isCopied, setCopied] = useState<any>(false);

  const { tokenId: tokenIdFromUrl } = useParams<{ tokenId?: string }>();
  const { chainId, account, provider } = useWeb3React();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const parsedTokenId = tokenIdFromUrl
    ? BigNumber.from(tokenIdFromUrl)
    : undefined;

  const {
    handleStake,
    loading: stakingLoading,
    txHash: stakingTxHash,
  } = useHandleStake(dispatch, account, chainId);

  const {
    handleUnStake,
    loading: unStakingLoading,
    txHash: unStakingTxHash,
  } = useHandleUnStake(dispatch);

  const { data: poolAddress } = useGetPoolAddressFromTOkenId(
    tokenIdFromUrl?.toString(),
    chainId
  );
  

  const { loading, position: positionDetails } =
    useV3PositionFromTokenId(parsedTokenId);

  const { poolId: poolIdPool, loading: poolIdLoading } = usePoolId(
    poolAddress?.position?.pool?.id
  );

  const [durationharvestRewards, setDurationHarvestRewards] =
    useState<any>(null);

  const { pendingRewards } = useGetPendingRewards(tokenIdFromUrl?.toString());

  const { oracleUsdPrice, loading: oracleUsdPriceLoading } =
    useGetUsdOraclePrice(
      pendingRewards / 10 ** 18,
      getChainAddresses(chainId ?? 1891)?.ElektrikUtility,
      1
    );

  const { data: harvestTimestamp } = useCheckHarvestTimeDuration(
    tokenIdFromUrl?.toString()
  );

  useEffect(() => {
    if (tokenIdFromUrl) {
      checkPositionIsStakedOrNotDetails(tokenIdFromUrl?.toString()).then(
        (id) => {
          if (!id?.lps?.length) {
            setIsStaked(false);
          } else if (id?.lps?.length && id?.lps[0]?.withdrawn) {
            setIsStaked(false);
          } else if (id?.lps?.length && !id?.lps[0]?.withdrawn) {
            setIsStaked(true);
          }
        }
      );
    }
  }, [tokenIdFromUrl?.toString(), stakingTxHash, unStakingTxHash]);

  const fetchDeposits = async (tokenIdFromUrl: any) => {
    try {
      const response = await fetchUserRewardsDepositTimestamp(tokenIdFromUrl);
      return response;
    } catch (error) {
      console.error("Error fetching liquidity data for pool", ":", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!harvestTimestamp?.harvests?.length) {
        const duration = await fetchDeposits(tokenIdFromUrl?.toString());
        setDurationHarvestRewards(
          duration?.deposits.length ? duration?.deposits[0]?.blockTimestamp : 0
        );
      } else {
        const t1 = Number(harvestTimestamp?.harvests[0]?.blockTimestamp);
        const t2 = Math.floor(Date.now() / 1000);
        const differenceInSeconds = t2 - t1;

        const days = Math.floor(differenceInSeconds / (60 * 60 * 24));
        const hours = Math.floor(
          (differenceInSeconds % (60 * 60 * 24)) / (60 * 60)
        );
        const minutes = Math.floor((differenceInSeconds % (60 * 60)) / 60);

        let durationString = "";
        if (days > 0) {
          durationString = `${days} day${days > 1 ? "s" : ""}`;
        } else if (hours > 0) {
          durationString = `${hours} hour${hours > 1 ? "s" : ""}`;
        } else {
          durationString = `${minutes} minute${minutes > 1 ? "s" : ""}`;
        }

        setDurationHarvestRewards(durationString);
      }
    };

    fetchData();
  }, [harvestTimestamp]);

  console.log("SSS", durationharvestRewards, getDaysFromTimestampRegex(1722930327))
  // 1722930327
  
  const {
    token0: token0Address,
    token1: token1Address,
    fee: feeAmount,
    liquidity,
    tickLower,
    tickUpper,
    tokenId,
  } = positionDetails || {};

  const removed = liquidity?.eq(0);

  const { data: poolData, loading: poolAddressLoading } = useSinglePoolData(
    poolAddress?.position?.pool?.id || ""
  );

  const { poolRewards: totalPoolRewardsAllocPoints } = useGetPoolRewardApr();

  const { poolRewards: poolAllocPoints } = useGetPoolAllocPoints(poolIdPool);

  useEffect(() => {
    if (poolAllocPoints && totalPoolRewardsAllocPoints) {
      const rewards = (poolAllocPoints / totalPoolRewardsAllocPoints) * 100;
      setAPrRewards(rewards);
    }
  }, [totalPoolRewardsAllocPoints, poolAllocPoints]);

  const { data: poolArray } = useCheckWhitelisted(
    poolAddress?.position?.pool?.id,
    chainId
  );

  useEffect(() => {
    if (poolArray && poolArray?.pools?.length) {
      setWhitelisted(true);
    } else {
      setWhitelisted(false);
    }
  }, [poolArray]);

  const token0 = useToken(token0Address);
  const token1 = useToken(token1Address);
  const price0 = useStablecoinPrice(token0 ?? undefined);
  const price1 = useStablecoinPrice(token1 ?? undefined);
  const [clicked, setIsclicked] = useState(false);
  const [positionData, setPositionData] = useState<any>(null);

  const currency0 = token0 ? unwrappedToken(token0) : undefined;
  const currency1 = token1 ? unwrappedToken(token1) : undefined;

  const [receiveWETH, setReceiveWETH] = useState(false);
  const nativeCurrency = useNativeCurrency(chainId);
  const nativeWrappedSymbol = nativeCurrency.wrapped.symbol;
  const [poolState, pool] = usePool(
    token0 ?? undefined,
    token1 ?? undefined,
    feeAmount
  );
  const position = useMemo(() => {
    if (
      pool &&
      liquidity &&
      typeof tickLower === "number" &&
      typeof tickUpper === "number"
    ) {
      return new Position({
        pool,
        liquidity: liquidity.toString(),
        tickLower,
        tickUpper,
      });
    }
    return undefined;
  }, [liquidity, pool, tickLower, tickUpper]);

  useEffect(() => {
    if (tokenId && account) {
      (async () => {
        const positionDetails = await fetchPositionData(
          parseInt(tokenId?._hex, 16).toString(),
          account,
          isStaked
        );
        setPositionData(positionDetails);
      })();
    }
  }, [tokenId, account, isStaked]);

  const pricesFromPosition = getPriceOrderingFromPositionForUI(position);
  const [manuallyInverted, setManuallyInverted] = useState(false);

  // handle manual inversion
  const { priceLower, priceUpper, base, quote } = useInverter({
    priceLower: pricesFromPosition.priceLower,
    priceUpper: pricesFromPosition.priceUpper,
    quote: pricesFromPosition.quote,
    base: pricesFromPosition.base,
    invert: manuallyInverted,
  });

  const inverted = token1 ? base?.equals(token1) : undefined;
  const currencyQuote = inverted ? currency0 : currency1;
  const currencyBase = inverted ? currency1 : currency0;

  const ratio = useMemo(() => {
    return priceLower && pool && priceUpper
      ? getRatio(
          inverted ? priceUpper.invert() : priceLower, // TODO: Check if this calculation is working fine or not pools with different token decimals 
          pool.token0Price,
          inverted ? priceLower.invert() : priceUpper
        )
      : undefined;
  }, [inverted, pool, priceLower, priceUpper]);

  const [feeValue0, feeValue1] = useV3PositionFees(
    pool ?? undefined,
    positionDetails?.tokenId,
    receiveWETH
  );

  const currency0ForFeeCollectionPurposes = pool
    ? receiveWETH
      ? pool.token0
      : unwrappedToken(pool.token0)
    : undefined;
  const currency1ForFeeCollectionPurposes = pool
    ? receiveWETH
      ? pool.token1
      : unwrappedToken(pool.token1)
    : undefined;
  console.log(
    "KSKKEE1111",
    currency0ForFeeCollectionPurposes,
    currency1ForFeeCollectionPurposes,
    currency0ForFeeCollectionPurposes &&
      CurrencyAmount.fromRawAmount(
        currency0ForFeeCollectionPurposes,
        0
      ).toExact(),currency1ForFeeCollectionPurposes &&
    CurrencyAmount.fromRawAmount(currency1ForFeeCollectionPurposes, 0).toExact()
  );
  const [collecting, setCollecting] = useState<boolean>(false);
  const [collectMigrationHash, setCollectMigrationHash] = useState<
    string | null
  >(null);
  const isCollectPending = useIsTransactionPending(
    collectMigrationHash ?? undefined
  );
  const [showConfirm, setShowConfirm] = useState(false);

  const fiatValueOfFees: CurrencyAmount<Currency> | null = useMemo(() => {
    if (!price0 || !price1 || !feeValue0 || !feeValue1) return null;

    const feeValue0Wrapped = feeValue0?.wrapped;
    const feeValue1Wrapped = feeValue1?.wrapped;

    if (!feeValue0Wrapped || !feeValue1Wrapped) return null;

    const amount0 = price0.quote(feeValue0Wrapped);
    const amount1 = price1.quote(feeValue1Wrapped);

    return amount0.add(amount1);
  }, [price0, price1, feeValue0, feeValue1]);

  const fiatValueOfLiquidity: CurrencyAmount<Token> | null = useMemo(() => {
    if (!price0 || !price1 || !position) return null;
    const amount0 = price0.quote(position.amount0);
    const amount1 = price1.quote(position.amount1);
    return amount0.add(amount1);
  }, [price0, price1, position]);

  // console.log("LSKSKKS",formatCurrencyAmount( position && position?.amount0, 4), formatPrice(price0), )

  const addTransaction = useTransactionAdder();
  const positionManager = useV3NFTPositionManagerContract();
  const collect = useCallback(() => {
    if (
      !currency0ForFeeCollectionPurposes ||
      !currency1ForFeeCollectionPurposes ||
      !chainId ||
      !positionManager ||
      !account ||
      !tokenId ||
      !provider
    )
      return;

    setCollecting(true);

    // we fall back to expecting 0 fees in case the fetch fails, which is safe in the
    // vast majority of cases
    const { calldata, value } =
      NonfungiblePositionManager.collectCallParameters({
        tokenId: tokenId.toString(),
        expectedCurrencyOwed0:
          feeValue0 ??
          CurrencyAmount.fromRawAmount(currency0ForFeeCollectionPurposes, 0),
        expectedCurrencyOwed1:
          feeValue1 ??
          CurrencyAmount.fromRawAmount(currency1ForFeeCollectionPurposes, 0),
        recipient: account,
      });

    const txn = {
      to: positionManager.address,
      data: calldata,
      value,
    };

    provider
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(estimate),
        };

        const mainnetFreeTxn = {
          ...txn,
          gasLimit: calculateGasMargin(estimate),
          gasPrice: 0,
        };

        return provider
          .getSigner()
          .sendTransaction(
            chainId === SupportedChainId.LIGHTLINK_PHOENIX_MAINNET
              ? mainnetFreeTxn
              : newTxn
          )
          .then((response: TransactionResponse) => {
            setCollectMigrationHash(response.hash);
            setCollecting(false);

            // sendEvent({
            //   category: "Liquidity",
            //   action: "CollectV3",
            //   label: [
            //     currency0ForFeeCollectionPurposes.symbol,
            //     currency1ForFeeCollectionPurposes.symbol,
            //   ].join("/"),
            // });

            addTransaction(response, {
              type: TransactionType.COLLECT_FEES,
              currencyId0: currencyId(currency0ForFeeCollectionPurposes),
              currencyId1: currencyId(currency1ForFeeCollectionPurposes),
              expectedCurrencyOwed0: CurrencyAmount.fromRawAmount(
                currency0ForFeeCollectionPurposes,
                0
              ).toExact(),
              expectedCurrencyOwed1: CurrencyAmount.fromRawAmount(
                currency1ForFeeCollectionPurposes,
                0
              ).toExact(),
            });
          });
      })
      .catch((error) => {
        setCollecting(false);
        // console.error(error)
      });
  }, [
    chainId,
    feeValue0,
    feeValue1,
    currency0ForFeeCollectionPurposes,
    currency1ForFeeCollectionPurposes,
    positionManager,
    account,
    tokenId,
    addTransaction,
    provider,
  ]);

  const {
    handleStakingRewards,
    loading: claimRewardsLoading,
    txHash: claimRewardsTxHash,
  } = useClaimStakingRewards(dispatch, account);

  const inputCurrency = useCurrency(
    getChainAddresses(chainId ?? 1891)?.NonFungibleToken,
    1891
  );

  const parsedAmount = useMemo(() => {
    return tryParseCurrencyAmount(
      tokenId?.toString(),
      inputCurrency ?? undefined
    );
  }, [inputCurrency, tokenId]);

  const [approvalA, approveACallback] = useApproveCallback(
    parsedAmount,
    getChainAddresses(chainId ?? 1891)?.NonFungibleToken
  );

  const showApprovalA =
    approvalA !== ApprovalState.APPROVED && !!tokenId?.toString();

  const owner = useSingleCallResult(
    tokenId ? positionManager : null,
    "ownerOf",
    [tokenId]
  ).result?.[0];
  const ownsNFT = owner === account || positionDetails?.operator === account;

  const feeValueUpper = inverted ? feeValue0 : feeValue1;
  const feeValueLower = inverted ? feeValue1 : feeValue0;

  // check if price is within range
  const below =
    pool && typeof tickLower === "number"
      ? pool.tickCurrent < tickLower
      : undefined;
  const above =
    pool && typeof tickUpper === "number"
      ? pool.tickCurrent >= tickUpper
      : undefined;
  const inRange: boolean =
    typeof below === "boolean" && typeof above === "boolean"
      ? !below && !above
      : false;

  const showCollectAsWeth = Boolean(
    ownsNFT &&
      (feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0)) &&
      currency0 &&
      currency1 &&
      (currency0.isNative || currency1.isNative) &&
      !collectMigrationHash
  );

  if (!positionDetails && !loading) {
    return <PositionPageUnsupportedContent />;
  }
  const tokenIdAddress0 =
    positionData &&
    positionData.positions &&
    positionData.positions[0]?.token0?.id;
  const tokenIdAddress1 =
    positionData &&
    positionData.positions &&
    positionData.positions[0]?.token1?.id;

  const poolLink = `/pools/single/${poolAddress?.position?.pool?.id}/detail`;

  const navigateToMyPositions = () => {
    navigate(`/pools/single/${poolAddress?.position?.pool?.id}/detail`);
  };

  const handleCopy = async () => {
    if (poolData) {
      await navigator.clipboard.writeText(poolData[0]?.address);
    }
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  function modalHeader() {
    return (
      <AutoColumn gap="md" style={{ marginTop: "20px" }}>
        <LightCard padding="12px 16px">
          <AutoColumn gap="12px">
            <RowBetween>
              <RowFixed>
                <CurrencyLogo
                  currency={feeValueUpper?.currency}
                  size="25px"
                  style={{
                    marginRight: "15px",
                    padding: "11px",
                    backgroundColor: "#112C35",
                    borderRadius: "50%",
                  }}
                />
                <ThemedText.DeprecatedMain>
                  {feeValueUpper ? formatCurrencyAmount(feeValueUpper, 4) : "-"}
                </ThemedText.DeprecatedMain>
              </RowFixed>
              <ThemedText.DeprecatedMain>
                {feeValueUpper?.currency?.symbol}
              </ThemedText.DeprecatedMain>
            </RowBetween>
            <RowBetween>
              <RowFixed>
                <CurrencyLogo
                  currency={feeValueLower?.currency}
                  size="25px"
                  style={{
                    marginRight: "15px",
                    padding: "11px",
                    backgroundColor: "#112C35",
                    borderRadius: "50%",
                  }}
                />
                <ThemedText.DeprecatedMain>
                  {feeValueLower ? formatCurrencyAmount(feeValueLower, 4) : "-"}
                </ThemedText.DeprecatedMain>
              </RowFixed>
              <ThemedText.DeprecatedMain>
                {feeValueLower?.currency?.symbol}
              </ThemedText.DeprecatedMain>
            </RowBetween>
          </AutoColumn>
        </LightCard>
        <ThemedText.DeprecatedItalic>
          <div>
            Collecting fees will withdraw currently available fees for you.
          </div>
        </ThemedText.DeprecatedItalic>
        <ButtonPrimary onClick={collect}>
          <div>Claim</div>
        </ButtonPrimary>
      </AutoColumn>
    );
  }
console.log("YYYY", getExplorerPrefix(chainId!))
  return (
    <Fragment>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={() => setShowConfirm(false)}
        attemptingTxn={collecting}
        hash={collectMigrationHash ?? ""}
        reviewContent={() => (
          <ConfirmationModalContent
            title={<div>Claim fees</div>}
            onDismiss={() => setShowConfirm(false)}
            topContent={modalHeader}
          />
        )}
        pendingText={<div>Collecting fees</div>}
      />
      <AutoColumn gap="30px" style={{ width: "100%" }}>
        <HeaderWrapperSinglePool>
          <RowFixed>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => window.history.back()}
            >
              <StyledChevronArrowLeft stroke={theme.textPrimary} />
              <ThemedText.DeprecatedWhite>
                Back to My Positions
              </ThemedText.DeprecatedWhite>
            </div>
          </RowFixed>

          <HeaderContentWrapper>
            <LeftSection>
              <WrappingDivPool>
                <TopSectionWrapper style={{ marginTop: 5 }}>
                  <PairDetails>
                    <PairIconsWithName>
                      <StackedIcons>
                        <DoubleCurrencyLogo
                          currency0={currencyBase}
                          currency1={currencyQuote}
                          size={32}
                          margin
                        />
                      </StackedIcons>
                      <div style={{ justifyContent: "left" }}>
                        {!token0?.symbol && !token1?.symbol && (
                          <LoadingBubble
                            height="25px"
                            width="80px"
                            delay="300ms"
                          />
                        )}
                        <ThemedText.HeadlineMedium
                          fontWeight={600}
                          fontSize={30}
                          marginRight={18}
                        >
                          <div>
                            {token0?.symbol} / {token1?.symbol}{" "}
                          </div>
                        </ThemedText.HeadlineMedium>
                        <PoolBadgeContainer>
                          {!tokenId ? (
                            <LoadingBubble
                              height="25px"
                              width="80px"
                              delay="300ms"
                            />
                          ) : (
                            <span style={{ fontWeight: 500, fontSize: 14 }}>
                              Position &nbsp;{tokenId?.toString()}
                            </span>
                          )}
                          &nbsp; &nbsp; &nbsp;
                          <RangeBadge removed={removed} inRange={inRange} />
                        </PoolBadgeContainer>
                      </div>
                      &nbsp;
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {!poolAddress && (
                          <LoadingBubble
                            height="25px"
                            width="80px"
                            delay="300ms"
                          />
                        )}
                        <WalletLink style={{ fontSize: 10 }}>
                          {poolAddress && poolAddress?.position?.pool?.id}
                        </WalletLink>
                        <a
                          style={{ marginLeft: "10px", marginRight: "5px" }}
                          target="_blank"
                          rel="noreferrer"
                          href={`https://${getExplorerPrefix(chainId!)}.io/address/${
                            poolAddress && poolAddress?.position?.pool?.id
                          }`}
                        >
                          <LetsIcon />
                        </a>
                        {isCopied ? (
                          <span
                            style={{
                              fontSize: "12px",
                              marginLeft: "10px",
                              color: "green",
                            }}
                          >
                            Copied
                          </span>
                        ) : (
                          <span
                            onClick={handleCopy}
                            style={{
                              marginLeft: "10px",
                              cursor: "pointer",
                              marginRight: 10,
                            }}
                          >
                            <CopyIcon />
                          </span>
                        )}
                      </div>
                    </PairIconsWithName>
                  </PairDetails>
                </TopSectionWrapper>
                <BottomSectionWrapper></BottomSectionWrapper>
              </WrappingDivPool>
            </LeftSection>
            <RightSection
              style={{ display: "grid", position: "relative", top: "-12px" }}
            >
              <TopSectionWrapperPosition>
                <WrappingDivPool>
                  <WalletContinerGrid>
                    {!position ? (
                      <LoadingBubble height="25px" width="80px" delay="300ms" />
                    ) : (
                      <TokenData>
                        {Number(position?.amount0.toSignificant(4))} &nbsp;
                        {token0?.symbol}
                      </TokenData>
                    )}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start  ",
                      }}
                    >
                      <span>
                        <WalletLink style={{ fontSize: 10, display: "flex" }}>
                          {tokenIdAddress0 &&
                            shortenAddress(tokenIdAddress0, 10)}
                        </WalletLink>{" "}
                        &nbsp;
                      </span>
                      <a
                        style={{ marginLeft: "10px", marginTop: "2px" }}
                        href={`https://${getExplorerPrefix(chainId!)}.io/token/${tokenIdAddress0}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LetsIcon />
                      </a>{" "}
                      &nbsp;
                      <TokenDelta style={{ color: "#868E9B", fontSize: 14 }}>
                        {" "}
                        ({`${ratio && ratio}`}%)
                      </TokenDelta>
                    </div>
                  </WalletContinerGrid>
                  <WalletContinerGrid>
                    {!position ? (
                      <LoadingBubble height="25px" width="80px" delay="300ms" />
                    ) : (
                      <TokenData>
                        {Number(position?.amount1.toSignificant(4))} &nbsp;
                        {token1?.symbol} &nbsp;
                      </TokenData>
                    )}
                    <div
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <span>
                        <WalletLink style={{ fontSize: 10, display: "flex" }}>
                          {tokenIdAddress1 &&
                            shortenAddress(tokenIdAddress1, 10)}
                        </WalletLink>{" "}
                        &nbsp;
                      </span>
                      <a
                        style={{ marginLeft: "10px", marginTop: "2px" }}
                        href={`https://${getExplorerPrefix(chainId!)}.io/token/${tokenIdAddress1}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LetsIcon />
                      </a>
                      &nbsp;
                      <TokenDelta style={{ color: "#868E9B", fontSize: 14 }}>
                        ({`${ratio && 100 - ratio}`}%)
                      </TokenDelta>
                    </div>
                  </WalletContinerGrid>
                  <PinkBageContainer>
                    <div
                      style={{
                        background:
                          "linear-gradient(194deg, #D96DFF 3.9%, #AD00FF 98.12%)",
                        fontSize: 10,
                        height: 20,
                        width: 90,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 12,
                        padding: "5px 5px",
                      }}
                    >
                      {positionDetails && feeTierPercent(positionDetails?.fee)}{" "}
                      Fee tier
                    </div>
                  </PinkBageContainer>
                </WrappingDivPool>
                <LeftBorderContainer>
                  {currency0 && currency1 && feeAmount && tokenId ? (
                    <ButtonPrimary
                      as={Link}
                      to={`/increase/${currencyId(currency0)}/${currencyId(
                        currency1
                      )}/${feeAmount}/${tokenId}`}
                      padding="12px 16px"
                      width="190px"
                      height="40px"
                      $borderRadius="12px"
                      style={{
                        height: "40px",
                        fontSize: "12px",
                        width: "150px",
                      }}
                    >
                      <div>Add Liquidity</div>
                    </ButtonPrimary>
                  ) : null}

                  {tokenId && !removed ? (
                    <ButtonPrimary
                      style={{
                        background: "none",
                        border: "1px solid #0fa1f1",
                        height: "40px",
                        fontSize: "12px",
                        width: "150px",
                        marginTop: 10,
                      }}
                      as={Link}
                      to={`/remove/${tokenId}`}
                      padding="12px 16px"
                      width="190px"
                      height="40px"
                      $borderRadius="10px"
                    >
                      <span style={{ color: "#0fa1f1" }}>
                        Remove Liquidity{" "}
                      </span>
                    </ButtonPrimary>
                  ) : null}
                </LeftBorderContainer>
              </TopSectionWrapperPosition>

              <BottomSectionWrapper></BottomSectionWrapper>
            </RightSection>
          </HeaderContentWrapper>
        </HeaderWrapperSinglePool>
        {loading || poolState === PoolState.LOADING || !feeAmount ? (
          <LoaderV2 />
        ) : (
          <AutoColumn gap="lg" style={{ width: "100%" }}>
            <PoolPageContentWrapperDetails>
              <PoolDetailPageWrapper>
                <PoolCardsContainer>
                  <WrappingDivPool style={{ width: "100%" }}>
                    <WrappingDivPool>
                      {(!isStaked || !whitelistedPools) && !clicked ? (
                        <CompositionTableWrapperStake>
                          <div
                            style={{
                              position: "absolute",
                              top: "30%",
                              left: "15%",
                              zIndex: 9999,
                              width: "70%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: 20,
                              }}
                            >
                              <ElektrikLogo
                                width={80}
                                height={80}
                                style={{ marginRight: "0px" }}
                              />
                            </div>

                            <ButtonPrimary onClick={() => setIsclicked(true)}>
                              Stake Now
                            </ButtonPrimary>
                            {!whitelistedPools && (
                              <div
                                style={{
                                  color: "red",
                                  margin: "10px 0px",
                                  fontWeight: 500,
                                }}
                              >
                                Pool is not whitelisted you can not stake and
                                unstake position.
                              </div>
                            )}
                          </div>
                          <BlurDiv>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "10px 0px 20px 0px",
                              }}
                            >
                              <div>Stake Your Position</div>
                              <div>
                                ID:{" "}
                                <span style={{ color: "#868E9B" }}>
                                  {tokenIdFromUrl}
                                </span>
                              </div>
                            </div>

                            <div
                              style={{ margin: "10px 0px", display: "flex" }}
                            >
                              <div style={{ display: "flex" }}>
                                <TokenLogos
                                  address={token0?.address}
                                  chainId={chainId}
                                />
                                <RightTokenLogo>
                                  <TokenLogos
                                    address={token1?.address}
                                    chainId={chainId}
                                  />
                                </RightTokenLogo>
                              </div>
                              <span>
                                <div>
                                  <TokenTextTitle>
                                    {token0?.symbol} / {token1?.symbol}{" "}
                                  </TokenTextTitle>
                                  <div style={{ display: "flex" }}>
                                    <span
                                      style={{
                                        background:
                                          " linear-gradient(194deg, #D96DFF 3.9%, #AD00FF 98.12%)",
                                        fontSize: 10,
                                        height: 20,
                                        width: 90,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 12,
                                        padding: "5px 5px",
                                        marginRight: "15px",
                                      }}
                                    >
                                      {positionDetails &&
                                        feeTierPercent(
                                          positionDetails?.fee
                                        )}{" "}
                                      fee tier
                                    </span>
                                    <CopyText toCopy="">
                                      <WalletLink style={{ fontSize: 10 }}>
                                        {" "}
                                        {poolAddress &&
                                          shortenAddress(
                                            poolAddress?.position?.pool?.id,
                                            10
                                          )}
                                      </WalletLink>
                                    </CopyText>
                                    <a
                                      style={{ marginLeft: "10px" }}
                                      href={`https://${getExplorerPrefix(chainId!)}.io/`}
                                    >
                                      <LetsIcon />
                                    </a>
                                  </div>
                                </div>
                              </span>
                              <div>
                                <span></span>
                              </div>
                            </div>

                            <div style={{ margin: "30px 0px 12px 0px" }}>
                              <div
                                style={{
                                  margin: "10px 0px 9px 32px",
                                  fontSize: "16px",
                                }}
                              >
                                Details
                              </div>
                              <div
                                style={{
                                  background: "#121B1E",
                                  borderRadius: 10,
                                  padding: "26px 35px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <div>APR:</div>
                                  <div>
                                    {poolData &&
                                      aprRewards &&
                                      poolData[0]?.feesAPR + aprRewards}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>Earn:</div>
                                  <div>ELTK + Fees</div>
                                </div>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                margin: "10px 0px 20px 0px",
                                height: "94px",
                              }}
                            >
                              <GradientConteinerLeft>
                                <ElektrikIcon style={{ marginTop: -20 }} />

                                <div style={{ marginLeft: "12px" }}>
                                  <GradientText>
                                    {" "}
                                    {pendingRewards || 0}{" "}
                                  </GradientText>
                                  <GreySubText>
                                    {/* ${oracleUsdPrice || "$0"} */}
                                  </GreySubText>
                                </div>
                              </GradientConteinerLeft>
                              <DarkContainerRight>
                                <CalimContainer>
                                  <ElektrikStakedContainer>
                                    <div>ELTK Earned</div>
                                    <GreySubTextV2>
                                      {getDaysFromTimestamp(
                                        durationharvestRewards
                                      ) || "0 Days"}
                                    </GreySubTextV2>
                                  </ElektrikStakedContainer>
                                  <div>
                                    <ButtonPrimary
                                      disabled={pendingRewards <= 0}
                                      style={{
                                        height: 42,
                                        width: 165,
                                        fontSize: 12,
                                        fontWeight: 600,
                                        background:
                                          "linear-gradient(235deg, #FFDFA1 8.32%, #F08302 97.93%)",
                                      }}
                                      onClick={() =>
                                        handleStakingRewards(tokenId)
                                      }
                                    >
                                      Claim Rewards
                                    </ButtonPrimary>
                                  </div>
                                </CalimContainer>
                              </DarkContainerRight>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                margin: "58px 0px 0px 0px",
                              }}
                            >
                              <div style={{ width: "48%" }}>
                                <ButtonOutlined
                                  disabled={!isStaked}
                                  onClick={() => handleUnStake(tokenId)}
                                  style={{ height: "54px", color: "#ffffff" }}
                                >
                                  Unstake
                                </ButtonOutlined>
                              </div>

                              <div style={{ width: "48%" }}>
                                <ButtonPrimary
                                  disabled={isStaked}
                                  onClick={() => handleStake(tokenId)}
                                  style={{ height: "54px" }}
                                >
                                  stake
                                </ButtonPrimary>
                              </div>
                            </div>
                          </BlurDiv>
                        </CompositionTableWrapperStake>
                      ) : (
                        <CompositionTableWrapperStake>
                          <WrappingDivPool>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "10px 0px 20px 0px",
                              }}
                            >
                              <WrappingDivPool>
                                Stake Your Position
                              </WrappingDivPool>
                              <WrappingDivPool>
                                ID:{" "}
                                <span style={{ color: "#868E9B" }}>
                                  {tokenIdFromUrl}
                                </span>
                              </WrappingDivPool>
                            </div>

                            <div
                              style={{
                                margin: "10px 0px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <DoubleCurrencyLogo
                                  currency0={currency0}
                                  currency1={currency1}
                                  size={56}
                                  margin
                                />
                                &nbsp; &nbsp;
                              </div>
                              <span>
                                <WrappingDivPool>
                                  <TokenTextTitle>
                                    {token0?.symbol} / {token1?.symbol}{" "}
                                  </TokenTextTitle>
                                  <div
                                    style={{ display: "flex", marginLeft: 10 }}
                                  >
                                    <span
                                      style={{
                                        background:
                                          " linear-gradient(194deg, #D96DFF 3.9%, #AD00FF 98.12%)",
                                        fontSize: 10,
                                        height: 20,
                                        width: 90,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: 12,
                                        padding: "5px 5px",
                                        marginRight: "15px",
                                      }}
                                    >
                                      {positionDetails &&
                                        feeTierPercent(
                                          positionDetails?.fee
                                        )}{" "}
                                      Fee tier
                                    </span>
                                    <WalletLink
                                      style={{
                                        fontSize: 10,
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {" "}
                                      {poolAddress &&
                                        shortenAddress(
                                          poolAddress?.position?.pool?.id,
                                          10
                                        )}
                                    </WalletLink>
                                    <a
                                      style={{ marginLeft: "10px" }}
                                      href={`https://${getExplorerPrefix(chainId!)}.io/address/${poolAddress?.position?.pool?.id}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <LetsIcon />
                                    </a>
                                  </div>
                                </WrappingDivPool>
                              </span>
                              <WrappingDivPool>
                                <span></span>
                              </WrappingDivPool>
                            </div>

                            <div style={{ margin: "30px 0px 12px 0px" }}>
                              <div
                                style={{
                                  margin: "10px 0px 9px 32px",
                                  fontSize: "16px",
                                }}
                              >
                                Details
                              </div>
                              <div
                                style={{
                                  background: "#121B1E",
                                  borderRadius: 12,
                                  padding: "26px 35px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "15px",
                                  }}
                                >
                                  <WrappingDiv>APRs:</WrappingDiv>
                                  <div>
                                    {poolData && aprRewards
                                      ? isNaN(poolData[0]?.feesAPR + aprRewards)
                                        ? "-"
                                        : (
                                            poolData[0]?.feesAPR + aprRewards
                                          )?.toFixed(2) || "-"
                                      : "-"}
                                    %
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <WrappingDivPool>Earn:</WrappingDivPool>
                                  <WrappingDivPool>ELTK + Fees</WrappingDivPool>
                                </div>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                margin: "10px 0px 20px 0px",
                                height: "94px",
                              }}
                            >
                              <GradientConteinerLeft>
                                <ElektrikIcon style={{ marginTop: "-20px" }} />
                                <div style={{ marginLeft: "12px" }}>
                                  <GradientText>
                                    {" "}
                                    {formatterWithoutDollar.format(
                                      pendingRewards / 10 ** 18
                                    ) || 0}
                                  </GradientText>
                                  {oracleUsdPrice ? (
                                    <GreySubText>
                                      {oracleUsdPrice
                                        ? formatter.format(
                                            oracleUsdPrice?.toFixed(2)
                                          )
                                        : "$0" || 0}
                                    </GreySubText>
                                  ) : (
                                    <GreySubText></GreySubText>
                                  )}
                                </div>
                              </GradientConteinerLeft>
                              <DarkContainerRight>
                                <CalimContainer>
                                  <ElektrikStakedContainer>
                                    <WrappingDivPool>
                                      ELTK Earned
                                    </WrappingDivPool>
                                    {/* {harvestTimestamp?.harvests?.length && <GreySubTextV2>over{durationharvestRewards}</GreySubTextV2> } */}
                                    {isStaked ? (
                                      <GreySubTextV2>
                                        {`${getDaysFromTimestampRegex(
                                          durationharvestRewards
                                        )}`}
                                      </GreySubTextV2>
                                    ) : (
                                      // <GreySubTextV2>
                                      //   over{" "}
                                      //   {Math.floor(
                                      //     (Date.now() -
                                      //       durationharvestRewards * 1000) /
                                      //       (1000 * 60 * 60 * 24)
                                      //   )}{" "}
                                      //   days
                                      // </GreySubTextV2>
                                      <GreySubTextV2>-</GreySubTextV2>
                                    )}
                                  </ElektrikStakedContainer>
                                  <WrappingDivPool>
                                    <ButtonPrimary
                                      disabled={pendingRewards <= 0}
                                      style={{
                                        height: 42,
                                        width: 165,
                                        fontSize: 12,
                                        fontWeight: 600,
                                        background:
                                          "linear-gradient(235deg, #FFDFA1 8.32%, #F08302 97.93%)",
                                      }}
                                      onClick={() =>
                                        handleStakingRewards(tokenId)
                                      }
                                    >
                                      Claim Rewards
                                    </ButtonPrimary>
                                  </WrappingDivPool>
                                </CalimContainer>
                              </DarkContainerRight>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                margin: "58px 0px 0px 0px",
                              }}
                            >
                              <div style={{ width: "48%" }}>
                                <ButtonOutlined
                                  disabled={!isStaked || !whitelistedPools}
                                  onClick={() => handleUnStake(tokenId)}
                                  style={{ height: "54px", color: "#ffffff" }}
                                >
                                  Unstake
                                </ButtonOutlined>
                              </div>

                              <div style={{ width: "48%" }}>
                                <ButtonPrimary
                                  disabled={isStaked || !whitelistedPools}
                                  onClick={() => handleStake(tokenId)}
                                  style={{ height: "54px" }}
                                >
                                  Stake
                                </ButtonPrimary>
                              </div>
                            </div>
                          </WrappingDivPool>
                        </CompositionTableWrapperStake>
                      )}
                    </WrappingDivPool>
                  </WrappingDivPool>
                  <AutoColumn
                    gap="20px"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <DarkCardGradient style={{ padding: "25px 30px " }}>
                      <AutoColumn
                        gap="md"
                        style={{ width: "100%", gridColumnGap: "10px" }}
                      >
                        <AutoColumn gap="md">
                          <LabelPosition>
                            <div style={{ fontSize: "18px" }}>
                              {" "}
                              Available Liquidity
                            </div>
                          </LabelPosition>
                          <WrappingDiv>
                            {fiatValueOfLiquidity?.greaterThan(
                              new Fraction(1, 100)
                            ) ? (
                              fiatValueOfLiquidity && (
                                <ThemedText.DeprecatedLargeHeader
                                  fontSize="32px"
                                  fontWeight={600}
                                  lineHeight="33px"
                                  height="51px"
                                  alignItems="center"
                                  display="flex"
                                >
                                  <div>
                                    $
                                    {formatCurrencyAmount(
                                      fiatValueOfLiquidity,
                                      4
                                    )}
                                  </div>
                                </ThemedText.DeprecatedLargeHeader>
                              )
                            ) : (
                              <ThemedText.DeprecatedLargeHeader
                                color={theme.textPrimary}
                                fontSize="36px"
                                fontWeight={600}
                              >
                                <div>$0</div>
                              </ThemedText.DeprecatedLargeHeader>
                            )}
                          </WrappingDiv>
                        </AutoColumn>
                        <LightCard padding="14px 16px">
                          <AutoColumn gap="12px">
                            <RowBetween>
                              <LinkedCurrencyDeprecated
                                chainId={chainId}
                                currency={currencyQuote}
                              />
                              <RowFixed>
                                <ThemedText.DeprecatedButton>
                                  {inverted
                                    ? position?.amount0.toSignificant(4)
                                    : position?.amount1.toSignificant(4)}
                                </ThemedText.DeprecatedButton>
                                {typeof ratio === "number" && !removed ? (
                                  <Badge style={{ marginLeft: "10px" }}>
                                    <span>
                                      <div>
                                        {inverted ? ratio : 100 - ratio}%
                                      </div>
                                    </span>
                                  </Badge>
                                ) : null}
                              </RowFixed>
                            </RowBetween>
                            <RowBetween>
                              <LinkedCurrencyDeprecated
                                chainId={chainId}
                                currency={currencyBase}
                              />
                              <RowFixed>
                                <ThemedText.DeprecatedButton>
                                  {inverted
                                    ? position?.amount1.toSignificant(4)
                                    : position?.amount0.toSignificant(4)}
                                </ThemedText.DeprecatedButton>
                                {typeof ratio === "number" && !removed ? (
                                  <Badge style={{ marginLeft: "10px" }}>
                                    <span>
                                      <div>
                                        {inverted ? 100 - ratio : ratio}%
                                      </div>
                                    </span>
                                  </Badge>
                                ) : null}
                              </RowFixed>
                            </RowBetween>
                          </AutoColumn>
                        </LightCard>
                      </AutoColumn>
                    </DarkCardGradient>

                    <DarkCardGradient style={{ padding: "25px 30px" }}>
                      <AutoColumn gap="md" style={{ width: "100%" }}>
                        <AutoColumn gap="md">
                          <RowBetween style={{ alignItems: "flex-start" }}>
                            <AutoColumn gap="md">
                              <LabelPosition>
                                <div style={{ fontSize: "18px" }}>
                                  Liquidity Fee Rewards
                                </div>
                              </LabelPosition>

                              {fiatValueOfFees?.greaterThan(
                                new Fraction(1, 100)
                              ) ? (
                                fiatValueOfFees && (
                                  <ThemedText.DeprecatedLargeHeader
                                    color={theme.accentSuccess}
                                    fontSize="32px"
                                    fontWeight={600}
                                    lineHeight="33px"
                                    height="51px"
                                    alignItems="center"
                                    display="flex"
                                  >
                                    <div>
                                      $
                                      {formatCurrencyAmount(fiatValueOfFees, 4)}
                                    </div>
                                  </ThemedText.DeprecatedLargeHeader>
                                )
                              ) : (
                                <ThemedText.DeprecatedLargeHeader
                                  color={theme.textPrimary}
                                  fontSize="36px"
                                  fontWeight={600}
                                >
                                  <div>$0</div>
                                </ThemedText.DeprecatedLargeHeader>
                              )}
                            </AutoColumn>
                            {ownsNFT &&
                            (feeValue0?.greaterThan(0) ||
                              feeValue1?.greaterThan(0) ||
                              !!collectMigrationHash) ? (
                              <ResponsiveButtonConfirmed
                                disabled={collecting || !!collectMigrationHash}
                                confirmed={
                                  !!collectMigrationHash && !isCollectPending
                                }
                                width="150px"
                                style={{
                                  borderRadius: "10px",
                                  padding: "12px 14px",
                                  width: "150px",
                                }}
                                padding="12px 14px"
                                onClick={() => setShowConfirm(true)}
                              >
                                {!!collectMigrationHash && !isCollectPending ? (
                                  <ThemedText.DeprecatedButton
                                    color={theme.textPrimary}
                                  >
                                    <div> Collected</div>
                                  </ThemedText.DeprecatedButton>
                                ) : isCollectPending || collecting ? (
                                  <ThemedText.DeprecatedButton
                                    color={theme.textPrimary}
                                  >
                                    {" "}
                                    <Dots>
                                      <div>Collecting</div>
                                    </Dots>
                                  </ThemedText.DeprecatedButton>
                                ) : (
                                  <>
                                    <ThemedText.DeprecatedButton
                                      color={theme.textPrimary}
                                    >
                                      <div>Take Reward</div>
                                    </ThemedText.DeprecatedButton>
                                  </>
                                )}
                              </ResponsiveButtonConfirmed>
                            ) : null}
                          </RowBetween>
                        </AutoColumn>
                        <LightCard padding="14px 16px">
                          <AutoColumn>
                            <RowBetween style={{ margin: "10px 0px" }}>
                              <RowFixed>
                                <CurrencyLogo
                                  currency={feeValueUpper?.currency}
                                  size="25px"
                                  style={{
                                    marginRight: "15px",
                                    padding: "11px",
                                    backgroundColor: "#112C35",
                                    borderRadius: "50%",
                                  }}
                                />
                                <ThemedText.DeprecatedButton>
                                  {feeValueUpper?.currency?.symbol}
                                </ThemedText.DeprecatedButton>
                              </RowFixed>
                              <RowFixed>
                                <ThemedText.DeprecatedButton>
                                  {feeValueUpper
                                    ? formatCurrencyAmount(feeValueUpper, 4)
                                    : "-"}
                                </ThemedText.DeprecatedButton>
                              </RowFixed>
                            </RowBetween>
                            <RowBetween>
                              <RowFixed>
                                <CurrencyLogo
                                  currency={feeValueLower?.currency}
                                  size="25px"
                                  style={{
                                    marginRight: "15px",
                                    padding: "11px",
                                    backgroundColor: "#112C35",
                                    borderRadius: "50%",
                                  }}
                                />
                                <ThemedText.DeprecatedButton>
                                  {feeValueLower?.currency?.symbol}
                                </ThemedText.DeprecatedButton>
                              </RowFixed>
                              <RowFixed>
                                <ThemedText.DeprecatedButton>
                                  {feeValueLower
                                    ? formatCurrencyAmount(feeValueLower, 4)
                                    : "-"}
                                </ThemedText.DeprecatedButton>
                              </RowFixed>
                            </RowBetween>
                          </AutoColumn>
                        </LightCard>
                        {showCollectAsWeth && (
                          <AutoColumn gap="md">
                            <RowBetween>
                              <ThemedText.DeprecatedButton>
                                <div>Collect as {nativeWrappedSymbol}</div>
                              </ThemedText.DeprecatedButton>
                              <Toggle
                                id="receive-as-weth"
                                isActive={receiveWETH}
                                toggle={() =>
                                  setReceiveWETH((receiveWETH) => !receiveWETH)
                                }
                              />
                            </RowBetween>
                          </AutoColumn>
                        )}
                      </AutoColumn>
                    </DarkCardGradient>
                  </AutoColumn>
                </PoolCardsContainer>

                <CompositionTableWrapperStakeChart>
                  <RowFixed style={{ marginBottom: 30 }}>
                    Price Range &nbsp; &nbsp;{" "}
                    <RangeBadge removed={removed} inRange={inRange} />
                  </RowFixed>
                  {poolData &&
                  poolData[0] &&
                  poolAddress &&
                  poolAddress?.position &&
                  poolAddress?.position?.pool?.id ? (
                    <LPDCHART
                      address={poolAddress?.position?.pool?.id}
                      poolData={poolData[0]}
                    />
                  ) : (
                    <LoaderV2 />
                  )}
                </CompositionTableWrapperStakeChart>
                <BackLogoDiv onClick={() => navigateToMyPositions()}>
                  <RowFixed>
                    <BackLogoGradientInnerDiv>
                      <StyledArrowLeftChevron
                        color="#000000"
                        style={{ display: "flex", padding: "4px" }}
                      />
                    </BackLogoGradientInnerDiv>
                  </RowFixed>
                </BackLogoDiv>
              </PoolDetailPageWrapper>
            </PoolPageContentWrapperDetails>
          </AutoColumn>
        )}
      </AutoColumn>
    </Fragment>
  );
}
