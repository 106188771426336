import { StyledArrowLeftChevron } from "components/NavigationTabs/styled";
import PositionListItem from "components/PositionListItem";
import PositionListItemOutrange from "components/PositionListItem/poisitionItemOutrange";
import { RowFixed } from "components/Row";
import { PositionListProps } from "types";

export default function PositionList({ positions }: PositionListProps) {
  return (
    <>
      {positions.map((p: any, index: any) => {
        return (
          <div key={p?.tokenId.toString()} >
            <PositionListItem {...p} />
          </div>
        );
      })}

      {positions.map((p: any, index: any) => {
        return (
          <div key={p?.tokenId.toString()} >
            <PositionListItemOutrange {...p} style={{ marginBottom: "10px" }}/>
          </div>
        );
      })}
    </>
  );
}
