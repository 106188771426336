import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
  StakingClient,
  StakingClientMainnet,
  StakingClientTestnet,
  apolloClient,
  voteEscrowClient,
} from "graphql/thegraph/apollo";
import { useEffect } from "react";

const GET_STAKED_IDS = gql`
  query MyQuery($fromAddress: String!, $v3PoolAddress: String!) {
    lps(where: { from: $fromAddress, pid_: { v3Pool: $v3PoolAddress } }) {
      id
    }
  }
`;
const GET_STAKED_IDS_POSITION = gql`
  query MyQuery($fromAddress: String!) {
    lps(where: { from: $fromAddress }) {
      id
      
    }
  }
`;

// const GET_STAKED_POSITIONS_DATA = gql`
// query userLiquidity($positionId: String!) {
//   positions(where: {id: $positionId}) {
//     id
//     liquidity
//     token0 {
//       id
//       symbol
//       name
//     }
//     token1 {
//       id
//       symbol
//       name
//     }
//     pool {
//       feeTier
//     }
//   }
// }
// `;

export const useGetStakedIdsForPool = (
  fromAddress: any,
  v3PoolAddress: any,
  chainId: any
) => {
  const { loading, error, data, refetch } = useQuery(GET_STAKED_IDS, {
    client: chainId == 1890 ? StakingClientMainnet : StakingClientTestnet,
    variables: { fromAddress, v3PoolAddress, chainId },
    fetchPolicy: "network-only",
  });

  return {
    data,
    loading,
    error,
  };
};

export const useGetStakedIds = (fromAddress: any, chainId: any) => {
  const { loading, error, data, refetch } = useQuery(GET_STAKED_IDS_POSITION, {
    client: chainId == 1890 ? StakingClientMainnet : StakingClientTestnet,
    variables: { fromAddress, chainId },
    fetchPolicy: "network-only",
  });

  return {
    data,
    loading,
    error,
  };
};
