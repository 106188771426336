
import {
  TableRow,
  TableText,
  TableTextPool,
  TableTextPoolBold,
} from "pages/Pool/poolStyle";
import {
  TokenLogoWrapper,
  ResponsiveGridPoolRow,
  StakedBalancesDiv,
  FlexDiv,
  FlexDivSymbol,
  ColoredSpan,
  DollarSpan,
  MyBalancesDiv,
  PoolBalancesDiv,
  PoolNameWrapperPool,
} from "./styleds";
import { useNavigate } from "react-router";
import {
  formatDollarAmountSuffix,
  formatNumberWithSuffix,
} from "utils/numbers";
import DoubleCurrencyLogo from "components/DoubleLogo";
import { useToken } from "hooks/Tokens";
import { unwrappedToken } from "utils/unwrappedToken";

import { useGetPoolRewardApr } from "hooks/staking/useGetPoolRewardApr";
import { useGetPoolAllocPoints } from "hooks/staking/useGetPoolAllocPoints";
import { useEffect, useMemo, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import {  useGetLps, useGetUnstakeLps, useLPSBalance } from "hooks/staking/useGetLps";
import { BigNumber } from "ethers";
import { usePoolId } from "hooks/pool/usePoolId";
import { feeTierPercent } from "utils";

export default function PoolRow(props: any) {
  const { item, myLiquidity } = props;
  const [aprRewards, setAPrRewards] = useState<any>(null);

  const { account, provider, chainId } = useWeb3React();
  const navigate = useNavigate();

  const token0 = useToken(item?.token0?.id);
  const token1 = useToken(item?.token1?.id);

  const currency0 = token0 ? unwrappedToken(token0) : undefined;
  const currency1 = token1 ? unwrappedToken(token1) : undefined;

  const { poolId, loading: poolIdLoading } = usePoolId(item?.id);
  const { poolRewards: totalPoolRewardsAllocPoints } = useGetPoolRewardApr();
  const { poolRewards: poolAllocPoints } = useGetPoolAllocPoints(poolId);

  useEffect(() => {
    if (poolAllocPoints && totalPoolRewardsAllocPoints) {
      const rewards =
        (Number(poolAllocPoints?.toString()) / totalPoolRewardsAllocPoints) *
        100;
      setAPrRewards(rewards);
    }
  }, [totalPoolRewardsAllocPoints, poolAllocPoints,chainId]);

  const { data: lp, loading: lpLoading } = useGetLps(account, item?.id,chainId);


  let lpids = lp?.lps.map((item:any)=>item?.id)

  const {data:LpBalanceData, loading:LpBalanceLoading} = useLPSBalance(lpids,chainId)

  const sumDepositedToken0 = useMemo(() => {
    if (!LpBalanceData || !LpBalanceData.positions) return 0;
    return LpBalanceData.positions.reduce((acc:any, position:any) => {
      const deposit = parseFloat(position.depositedToken0);
      const withdrawn = parseFloat(position.withdrawnToken0);
      return acc + (deposit - withdrawn);
    }, 0);
  }, [LpBalanceData]);

  const sumDepositedToken1 = useMemo(() => {
    if (!LpBalanceData || !LpBalanceData.positions) return 0;
    return LpBalanceData.positions.reduce((acc:any, position:any) => {
      const deposit = parseFloat(position.depositedToken1);
      const withdrawn = parseFloat(position.withdrawnToken1);

      return acc + (deposit-withdrawn);
    }, 0);
  }, [LpBalanceData]);


  const { data: Allp, loading: AlllpLoading } = useGetUnstakeLps(
    account,
    item?.id,
    chainId
  );


  let Allpids = Allp && Allp?.positions.map((item:any)=>item?.id)
  const {data:ALLpBalance, loading:ALLpBalanceLoading} = useLPSBalance(Allpids,chainId)

  const lpsumDepositedToken0 = useMemo(() => {
    if (!ALLpBalance || !ALLpBalance.positions) return 0;
    return ALLpBalance.positions.reduce((acc:any, position:any) => {
      const deposit = parseFloat(position.depositedToken0);
      const withdrawn = parseFloat(position.withdrawnToken0);

      return acc + (deposit-withdrawn);
    }, 0);
  }, [ALLpBalance]);

  const lpsumDepositedToken1 = useMemo(() => {
    if (!ALLpBalance || !ALLpBalance.positions) return 0;
    return ALLpBalance.positions.reduce((acc:any, position:any) => {
      const deposit = parseFloat(position.depositedToken1);
      const withdrawn = parseFloat(position.withdrawnToken1);   
      return acc + (deposit-withdrawn);
    }, 0);
  }, [ALLpBalance]);

  console.log("KKKSKSK",lpsumDepositedToken0, lpsumDepositedToken1)


  const finalPoolBalance0 = lpsumDepositedToken0 + sumDepositedToken0;
  const finalPoolBalance1 =  lpsumDepositedToken1 + sumDepositedToken1;






  const transformedArray =
    Allp &&
    Allp?.positions.map((item: any) => ({
      __typename: "LP",
      id: item.id,
      liquidity: item.liquidity,
      tickLower: parseInt(item.tickLower.tickIdx),
      tickUpper: parseInt(item.tickUpper.tickIdx),
      pid: {
        __typename: "Pool",
        v3Pool: item.pool.id,
      },
    }));

  const mergedArray = lp && Allp && [...lp?.lps, ...transformedArray];


  const navigateToPool = (pool: any) => {
    navigate(`/pools/single/${pool?.id}/detail`);
  };

  const One = BigNumber.from(10).pow(18).toString();
  const Six = BigNumber.from(10).pow(6).toString();

  let totalLiquidity = BigNumber.from(0);
  item?.positions?.forEach((item:any) => {
    totalLiquidity = totalLiquidity?.add(BigNumber.from(item?.liquidity));
  });

let finalLiquidity = Number(totalLiquidity.toString());

  return (
    <TableRow onClick={() => navigateToPool(item)}>
      <ResponsiveGridPoolRow>
        <PoolNameWrapperPool>
          <TokenLogoWrapper style={{ cursor: "pointer" }}>
            <DoubleCurrencyLogo
              currency0={currency0}
              currency1={currency1}
              size={26}
              margin
            />
          </TokenLogoWrapper>
          <TableText>
            <div>
              <div style={{ fontWeight: 500 }}>
                {item?.token1?.symbol} / {item?.token0?.symbol}
              </div>
              <div
                style={{
                  color: "#868E9B",
                  fontSize: '11px',
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {feeTierPercent(item?.feeTier)}
              </div>
            </div>
          </TableText>
        </PoolNameWrapperPool>

        {myLiquidity && (
          <>
            <TableText>
              <DollarSpan>
                {/* {finalLiquidity.toString()} */}
                {formatNumberWithSuffix(finalLiquidity) }
              </DollarSpan>
            </TableText>
          </>
        )}

        {!myLiquidity && (
          <TableTextPoolBold title={aprRewards} style={{marginLeft:10}}>
            <DollarSpan>
              <div>{aprRewards?.toFixed(2) || "0"}%</div>
            </DollarSpan>
          </TableTextPoolBold>
        )}

        <TableTextPool
          title={item?.totalValueLockedUSD}
          style={{ fontWeight: 500, fontSize: 15, padding:5 }}
        >
          <div>{formatDollarAmountSuffix(item?.totalValueLockedUSD)} </div>
        </TableTextPool>

        <TableTextPool
          title={item?.volumeUSD}
          style={{ fontWeight: 500, fontSize: 15, padding:5 }}
        >
          <div> {formatDollarAmountSuffix(item?.volumeUSD)} </div>
        </TableTextPool>

        <PoolBalancesDiv>
          <FlexDiv style={{ display: "flex" }}>
            <FlexDivSymbol>
              <TableTextPoolBold
                title={Number(item?.totalValueLockedToken0)?.toFixed(2)}
              >
                <DollarSpan style={{ fontWeight: 500, fontSize: 15 }}>
                  {formatNumberWithSuffix(Number(item?.totalValueLockedToken0))}
                </DollarSpan>
              </TableTextPoolBold>
              &nbsp;
              <ColoredSpan style={{ color: "#868E9B" }}>
                {item?.token0?.symbol}
              </ColoredSpan>
            </FlexDivSymbol>
          </FlexDiv>

          <FlexDiv style={{ display: "flex" }}>
            <FlexDivSymbol>
              <TableTextPoolBold
                title={Number(item?.totalValueLockedToken1)?.toFixed(2)}
              >
                <DollarSpan>
                  {formatNumberWithSuffix(Number(item?.totalValueLockedToken1))}
                </DollarSpan>
              </TableTextPoolBold>
              &nbsp; <ColoredSpan> {item?.token1?.symbol} </ColoredSpan>
            </FlexDivSymbol>
          </FlexDiv>
          
        </PoolBalancesDiv>
        <TableTextPool
          title={item?.poolDayData[0]?.feesUSD}
          style={{ fontWeight: 500, fontSize: 15, padding:0 }}
        >
          <div>
            {formatDollarAmountSuffix(item?.poolDayData[0]?.feesUSD)}
          </div>
        </TableTextPool>

        <MyBalancesDiv>
          <FlexDiv>
            <FlexDivSymbol>
              <TableTextPoolBold
                title={finalPoolBalance0}
              >
                <DollarSpan>
                  {formatNumberWithSuffix(
                    Number(
                      finalPoolBalance0
                    )
                  )}
                </DollarSpan>
              </TableTextPoolBold>
              &nbsp;
              <ColoredSpan>{item?.token0?.symbol}</ColoredSpan>
            </FlexDivSymbol>
          </FlexDiv>

          <FlexDiv>
            <FlexDivSymbol>
              <TableTextPoolBold
                title={finalPoolBalance1}
              >
                <DollarSpan style={{ fontWeight: 500, fontSize: 15 }}>
                  {formatNumberWithSuffix(
                    Number(
                      finalPoolBalance1
                    )
                  )}
                </DollarSpan>
              </TableTextPoolBold>
              &nbsp;{" "}
              <ColoredSpan style={{ color: "#868E9B" }}>
                {" "}
                {item?.token1?.symbol}{" "}
              </ColoredSpan>
            </FlexDivSymbol>
          </FlexDiv>
        </MyBalancesDiv>

        <StakedBalancesDiv>
          <FlexDiv>
            <FlexDivSymbol>
              <TableTextPoolBold
                title={sumDepositedToken0}
              >
                <DollarSpan>
                  {formatNumberWithSuffix(
                    Number(
                      sumDepositedToken0
                    )
                  )}
                </DollarSpan>
              </TableTextPoolBold>
              &nbsp;
              <ColoredSpan>{item?.token0?.symbol}</ColoredSpan>
            </FlexDivSymbol>
          </FlexDiv>

          <FlexDiv style={{ cursor: "pointer" }}>
            <FlexDivSymbol>
              <TableTextPoolBold
                title={sumDepositedToken1}
              >
                <DollarSpan>
                  {formatNumberWithSuffix(
                    Number(
                      sumDepositedToken1
                    )
                  )}
                </DollarSpan>
              </TableTextPoolBold>
              &nbsp; <ColoredSpan> {item?.token1?.symbol} </ColoredSpan>
            </FlexDivSymbol>
          </FlexDiv>
        </StakedBalancesDiv>
      </ResponsiveGridPoolRow>
    </TableRow>
  );
}
