import { useWeb3React } from "@web3-react/core";
import { useFilterPossiblyMaliciousPositions } from "hooks/useFilterPossiblyMaliciousPositions";
import { useV3Positions } from "hooks/useV3Positions";
import { Fragment, useMemo, useState } from "react";
import { useUserHideClosedPositions } from "state/user/hooks";
import { PositionDetails } from "types/position";
import {
  ErrorContainer,
  MainContentWrapperPositions,
  ResponsiveButtonPrimary,
  PositionsTitle,
  PositionHeaderTitle,
} from "./styleds";
import { ThemedText } from "theme";
import { ButtonPrimary, ButtonText } from "components/Button";
import { useToggleAccountDrawer } from "components/AccountDrawer";
import styled, { css, useTheme } from "styled-components/macro";
import { Inbox } from "react-feather";
import PositionList from "components/PositionList";
import { Link, useNavigate } from "react-router-dom";
import { ElektrikLoader, LoaderV2 } from "components/Icons/LoadingSpinner";
import { RowFixed } from "components/Row";
import { StyledArrowLeftChevron } from "components/NavigationTabs/styled";
import { PositionsData } from "types/pools";
import { useGetStakedPositionsData } from "hooks/pool/useGetStakedPositionData";
import {
  useGetStakedIds,
  useGetStakedIdsForPool,
} from "hooks/pool/useGetStakedIdsForPool";
const { BigNumber } = require("ethers"); // Import BigNumber from ethers

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

export default function PoolsPosition() {
  const { account, chainId } = useWeb3React();
  const navigate = useNavigate();
  const theme = useTheme();
  const [positionsData, setPositionsData] = useState<PositionsData>({});

  const [userHideClosedPositions, setUserHideClosedPositions] =
    useUserHideClosedPositions();
  const { positions, loading: positionsLoading } = useV3Positions(account);

  const [openPositions, closedPositions] = positions?.reduce<
    [PositionDetails[], PositionDetails[]]
  >(
    (acc, p) => {
      acc[p.liquidity?.isZero() ? 1 : 0].push(p);
      return acc;
    },
    [[], []]
  ) ?? [[], []];

  const userSelectedPositionSet = useMemo(
    () => [
      ...openPositions,
      ...(userHideClosedPositions ? [] : closedPositions),
    ],
    [closedPositions, openPositions, userHideClosedPositions]
  );

  const showConnectAWallet = Boolean(!account);

  const filteredPositions = useFilterPossiblyMaliciousPositions(
    userSelectedPositionSet
  );

  const toggleWalletDrawer = useToggleAccountDrawer();



  const { data: stakedIds, loading: StakedIdsLoading } = useGetStakedIds(
    account,
    chainId
  );

  const { stakedPositionData, loading: StakedPositionsDataLoading } =
    useGetStakedPositionsData(stakedIds?.lps, chainId);

  const consolidatedResponse = {
    positions: stakedPositionData.reduce(
      (accumulator: any, currentArray: any) => {
        accumulator.push(...currentArray.positions);
        return accumulator;
      },
      []
    ),
  };
  function updateObject(obj: any) {
    // Rename "id" to "tokenId" in the main object
    if (obj.hasOwnProperty("id")) {
      obj.tokenId = BigNumber.from(obj.id);
      delete obj.id;
    }

    if (obj.hasOwnProperty("liquidity")) {
      obj.liquidity = BigNumber.from(obj.liquidity);
      delete obj.id;
    }

    // Extract and update "token0" and "token1" fields
    if (obj.token0 && obj.token0.hasOwnProperty("id")) {
      obj.token0 = obj.token0.id; // Set token0 to its ID
    }

    if (obj.token1 && obj.token1.hasOwnProperty("id")) {
      obj.token1 = obj.token1.id; // Set token1 to its ID
    }

    // Extract and update "tickLower" and "tickUpper" fields
    if (obj.tickLower && obj.tickLower.hasOwnProperty("tickIdx")) {
      obj.tickLower = obj.tickLower.tickIdx; // Set tickLower to its tickIdx value
    }

    if (obj.tickUpper && obj.tickUpper.hasOwnProperty("tickIdx")) {
      obj.tickUpper = obj.tickUpper.tickIdx; // Set tickUpper to its tickIdx value
    }

    // Add "fee" key with value from "pool.feeTier"
    if (obj.pool && obj.pool.hasOwnProperty("feeTier")) {
      obj.fee = obj.pool.feeTier;
    }

    return obj;
  }

  let ResponseArray = consolidatedResponse?.positions;
  const updatedArray = ResponseArray.map(updateObject);

  const mergedObject = [
    ...(userSelectedPositionSet.slice(1) || []),
    ...(updatedArray.slice(1) || []),
  ];
  const navigateToMyPool = () => {
    navigate(`/pools`);
  };

  return (
    <Fragment>
      <MainContentWrapperPositions
        style={{ minWidth: "80%", margin: "20px 0px" }}
      >
        {!positionsLoading && (
          <PositionHeaderTitle>
            <div style={{ display: "flex", alignItems: "center" }}>
              <PositionsTitle>My Positions</PositionsTitle>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <ResponsiveButtonPrimary
                data-cy="join-pool-button"
                id="join-pool-button"
                as={Link}
                to="/add"
                style={{
                  background: `linear-gradient(146deg, #a1eeff 0%, #029af0 100%)`,
                  color: "#000",
                  height: "46px",
                  width: "200px",
                  padding: "14px 14px",
                  fontSize: 14,
                }}
              >
                + <div>Add New Position</div>
              </ResponsiveButtonPrimary>
            </div>
          </PositionHeaderTitle>
        )}

        {positionsLoading ? (
          <ElektrikLoader fill={true} />
        ) : filteredPositions &&
          closedPositions &&
          filteredPositions.length > 0 ? (
          <PositionList
            positions={mergedObject}
            setUserHideClosedPositions={setUserHideClosedPositions}
            userHideClosedPositions={userHideClosedPositions}
          />
        ) : (
          <ErrorContainer style={{ height: "60vh" }}>
            <ThemedText.DeprecatedBody
              color={theme.textTertiary}
              textAlign="center"
            >
              <InboxIcon strokeWidth={1} style={{ marginTop: "2em" }} />
              <div>
                <div>Your active liquidity positions will appear here.</div>
              </div>
            </ThemedText.DeprecatedBody>
            {!showConnectAWallet && closedPositions.length > 0 && (
              <ButtonText
                style={{
                  marginTop: ".5rem",
                  background: `linear-gradient(146deg, #a1eeff 0%, #029af0 100%)`,
                  color: "#000",
                  padding: "10px",
                  marginBottom: "10px",
                }}
                onClick={() =>
                  setUserHideClosedPositions(!userHideClosedPositions)
                }
              >
                <div>Show closed positions</div>
              </ButtonText>
            )}
            {showConnectAWallet && (
              <ButtonPrimary
                style={{
                  marginTop: "2em",
                  marginBottom: "2em",
                  padding: "8px 16px",
                  background: `linear-gradient(146deg, #a1eeff 0%, #029af0 100%)`,
                  color: "#000",
                  borderRadius: "10px",
                }}
                onClick={toggleWalletDrawer}
              >
                <div>Connect wallet</div>
              </ButtonPrimary>
            )}
          </ErrorContainer>
        )}
        {mergedObject.length ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
              top: -20,
              marginTop: 30,
              marginBottom: 20,
            }}
            onClick={() => navigateToMyPool()}
          >
            <RowFixed>
              <div
                style={{
                  borderRadius: "50%",
                  background:
                    "linear-gradient(91deg, #A1EEFF 7.28%, #029AF0 89.82%)",
                  cursor: "pointer",
                }}
              >
                <StyledArrowLeftChevron
                  color="#000000"
                  style={{ display: "flex", padding: "4px" }}
                />
              </div>
            </RowFixed>
          </div>
        ) : (
          <></>
        )}
      </MainContentWrapperPositions>
    </Fragment>
  );
}
